import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ChartJs from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import _ from 'lodash'

import { max } from 'lodash'

import ChartDataset from '../../core/models/ChartDataset'

export default class Chart extends Component {
  state = { chartInfo: {} }

  componentDidMount() {
    this._isMounted = true
    this.defineChartInfo()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props

    if (!_.isEqual(data, prevProps.data)) {
      this.defineChartInfo()
    }
  }

  defineChartInfo = () => {
    const {
      id,
      type,
      data,
      title,
      labelFontSize,
      displayLegend,
      extraYScales,
      extraXScales,
      shouldUpdateInfo,
      handShakeFn,
      shouldHavePlugin
    } = this.props

    if (data instanceof ChartDataset && shouldUpdateInfo) {
      if (this.state.chartInfo instanceof ChartJs) {
        this.state.chartInfo.destroy()
      }

      handShakeFn()

      const isMobile = window.innerWidth <= 768
      const plugins = shouldHavePlugin ? [ChartDataLabels] : []

      if ('datasets' in data) {
        for (let i = 0; i < data.datasets.length; i += 1) {
          const element = data.datasets[i]
          for (let j = 0; j < element.data.length; j += 1) {
            if (element.data[j] === undefined) element.data[j] = null
          }
        }
      }

      this._isMounted &&
        this.setState({
          chartInfo: new ChartJs(id, {
            type: isMobile ? 'horizontalBar' : type,
            data,
            plugins,
            options: {
              animation: false,
              responsive: true,
              maintainAspectRatio: false,
              title: {
                display: !!title,
                text: title,
                padding: isMobile ? 20 : 25,
                fontSize: isMobile ? 20 : 25
              },
              legend: {
                display: displayLegend,
                labels: {
                  fontSize: isMobile ? 12 : 15,
                  fontStyle: 'bold'
                }
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      min: 0,
                      suggestedMax: this.defineMaxYScale(data.datasets),
                      display: true,
                      fontSize: isMobile ? 16 : labelFontSize
                    }
                  },
                  extraYScales
                ],
                xAxes: [
                  {
                    ticks: {
                      display: true,
                      fontSize: isMobile ? 16 : labelFontSize
                    }
                  },
                  extraXScales
                ]
              }
            }
          })
        })
    }
  }

  defineMaxYScale(chartDatasets) {
    return max(chartDatasets.map(dataset => max(dataset.data))) * 1.05
  }

  render() {
    return (
      <div
        className="canvas-wrapper"
        style={{
          height: this.props.height,
          width: this.props.width,
          display: 'inline-block'
        }}
      >
        <canvas id={this.props.id} />
      </div>
    )
  }
}

Chart.propTypes = {
  title: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  displayLegend: PropTypes.bool,
  labelFontSize: PropTypes.number,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    data: PropTypes.shape({})
  }).isRequired,
  extraYScales: PropTypes.shape({}),
  extraXScales: PropTypes.shape({})
}

Chart.defaultProps = {
  title: '',
  labelFontSize: 10,
  height: '100vh',
  width: '100%',
  displayLegend: true,
  extraXScales: {
    display: false
  },
  extraYScales: {
    display: false
  }
}
