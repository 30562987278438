import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import WallboardCardDataItem from './WallboardCardDataItem'
import WallboardCardDataTable from './WallboardCardDataTable'

import style from './style.module.css'

const withDividerTitles = [
  { mainTitle: 'Nível de Serviço', concatenatedWith: 'NSI V2' },
  { mainTitle: '% Perdidas', concatenatedWith: 'TME' },
  { mainTitle: 'TMI V2', concatenatedWith: 'TMA' }
]

const cardsToRemove = ['NSI V2', 'TME', 'TMA']

const cardsToKeepOnPhone = ['TMA']

const WallboardCardData = props => {
  const defineContentItemsClass = () => {
    const { isVertical, bigHeightItems } = props
    if (isVertical && bigHeightItems) {
      return style.cardContentItemsVerticalBigAside
    } else if (isVertical) {
      return style.cardContentItemsVerticalAside
    }
    return style.cardContentItemsAside
  }

  const defineItemColors = index => {
    if (!index && !props.isVertical && props.data.length > 2 && props.firstItemHightlight) {
      return 'LightGrey'
    }
    return ''
  }

  const renderCardWithDivider = title => {
    const { data } = props

    const withDivider = withDividerTitles.find(({ mainTitle }) => mainTitle === title)

    if (withDivider) {
      const concatenatedData = data.find(
        ({ title: mainTitle }) => mainTitle === withDivider.concatenatedWith
      )

      return concatenatedData
    }

    return false
  }

  const renderCardDataItems = () => {
    const { isVertical, data, bigHeightItems, channel, unifiedCard, smallText } = props
    return data.map((d, index) => {
      if (
        cardsToRemove.find(title => title === d.title) &&
        !renderCardWithDivider(d.title) &&
        !(channel === 1 && cardsToKeepOnPhone.find(title => title === d.title))
      ) {
        return false
      }

      return (
        <WallboardCardDataItem
          key={shortid.generate()}
          color={defineItemColors(index)}
          data={d}
          isChannelEmail={props.isChannelEmail}
          smallText={smallText}
          channel={channel}
          withDivider={renderCardWithDivider(d.title)}
          width={100 / (props.isChannelEmail ? data.length : data.length - cardsToRemove.length)}
          isVertical={isVertical}
          bigHeightItems={bigHeightItems}
          unifiedCard={unifiedCard}
        />
      )
    })
  }

  const renderTable = () => {
    const { hasTable, tableData, writtenTable, channel } = props
    return hasTable ? (
      <WallboardCardDataTable tableData={tableData} writtenTable={writtenTable} channel={channel} />
    ) : null
  }

  return (
    <div className={style.cardContent}>
      {props.withNoCards ? null : (
        <div
          className={defineContentItemsClass()}
          style={{
            width: `${props.isVertical ? '100%' : `${props.width}%`}`
          }}
        >
          {renderCardDataItems()}
        </div>
      )}
      {props.withNoTable && !props.writtenTable ? null : renderTable()}
    </div>
  )
}

WallboardCardData.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      info: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      info: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  width: PropTypes.number,
  isVertical: PropTypes.bool,
  hasTable: PropTypes.bool,
  firstItemHightlight: PropTypes.bool,
  bigHeightItems: PropTypes.bool,
  withNoCards: PropTypes.bool,
  withNoTable: PropTypes.bool,
  writtenTable: PropTypes.bool,
  unifiedCard: PropTypes.bool
}

WallboardCardData.defaultProps = {
  data: [],
  tableData: [],
  width: 100,
  isVertical: false,
  hasTable: true,
  firstItemHightlight: true,
  bigHeightItems: false,
  withNoCards: false,
  withNoTable: false,
  writtenTable: false,
  unifiedCard: false
}

export default WallboardCardData
