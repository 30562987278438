import React, { Component } from 'react'

import WallboardCard from '../WallboardCard'
import WallboardCardData from '../WallboardCard/WallboardCardData'

import WallboardSubjectData from '../../core/models/WallboardSubjectData'

import UtilsFilters from '../../utils/utilsFilters'
import { isObjectWithProps } from '../../core/helpers/GenericHelper'

class TopSubjects extends Component {
  state = {
    subjectsData: []
  }

  componentDidMount() {
    this._isMounted = true

    if (isObjectWithProps(this.props.nocSubjects)) {
      this.defineSubjects()
    }
  }

  componentDidUpdate(prevProps) {
    const { nocSubjects } = this.props

    if (
      (!isObjectWithProps(prevProps.nocSubjects) && isObjectWithProps(nocSubjects)) ||
      prevProps.nocSubjects !== nocSubjects
    ) {
      this.defineSubjects()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  defineSubjects = () => {
    const { top_300 } = this.props.nocSubjects

    const subjectsData = UtilsFilters.filterTop(top_300, 3, 'd_0').map(
      d => new WallboardSubjectData(d)
    )

    this._isMounted && this.setState({ subjectsData })
  }

  render() {
    const { subjectsData } = this.state
    const { mode } = this.props
    return (
      <WallboardCard width={33.33} title="Top Assuntos do Dia" mode={mode}>
        <WallboardCardData isVertical hasTable={false} data={subjectsData} />
      </WallboardCard>
    )
  }
}

export default TopSubjects
