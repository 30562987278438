import React, { Component } from 'react'
import style from './style.module.css'

import AnalyticsServices from '../../core/services/AnalyticsServices'

class LoginSalesforce extends Component {
  componentDidUpdate(prevProps) {
    const { triggerLogin } = this.props

    if (triggerLogin && !prevProps.triggerLogin) {
      this.loginSalesforce()
    }
  }

  loginSalesforce = () => {
    this.props.setLoading(true)

    AnalyticsServices.getOauthSalesforceConfigurations()
      .then(({ data }) => {
        const { clientId, url } = data

        const params = new URLSearchParams({
          client_id: clientId,
          redirect_uri: `${window.location.origin}/`,
          response_type: 'code'
        })

        window.open(`${url}/services/oauth2/authorize?${params.toString()}`, '_self')
      })
      .catch(err => {
        console.error(err)
        this.props.setLoading(false)
      })
  }

  render = () => (
    <div>
      <input
        type="submit"
        className={style.login__button}
        onClick={this.loginSalesforce}
        disabled={this.props.isLoading}
        value="Login Salesforce"
      />
    </div>
  )
}

export default LoginSalesforce
