export default class ChartIndex {
  constructor(label, backgroundColor, type, yAxisID = '', xAxisID = '') {
    const isMobile = window.innerWidth <= 768

    switch (type) {
      case 'bar':
        this.label = label
        this.type = isMobile ? 'horizontalBar' : 'bar'
        this.data = []
        this.borderWidth = 2
        this.backgroundColor = backgroundColor
        this.borderColor = backgroundColor.replace('0.7', '1.0')
        this.datalabels = {
          display: true,
          textAlign: 'center',
          anchor: 'end',
          align: 'end',
          font: {
            style: 'bold',
            size: isMobile ? 14 : 20
          }
        }
        break
      case 'line':
        this.label = label
        this.type = 'line'
        this.data = []
        this.backgroundColor = 'rgba(255, 255, 255, 0)'
        this.borderColor = backgroundColor
        this.borderWidth = 3
        this.datalabels = {
          display: false
        }
        this.yAxisID = yAxisID
        this.xAxisID = xAxisID
        break
      default:
        this.data = []
        break
    }
  }
}
