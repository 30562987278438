import React from 'react'

import style from '../../components/IncidentsCard/style.module.css'

export default class OccurrenceNode {
  constructor(data) {
    if (data === 0) {
      return [
        <div>
          <div className={style.carousellTitle} />
          <div className={style.carousellContent}>Nenhum incidente ativo</div>
        </div>
      ]
    }
    return (
      <div>
        <div className={style.carousellTitle}>{data.nome_incidente__c}</div>
        <div className={style.carousellContent}>{data.descri_o_do_incidente__c}</div>
      </div>
    )
  }
}
