import React from 'react'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faCommentDots, faEnvelope } from '@fortawesome/fontawesome-free-solid'

import Switch from '../../components/Switch'

import style from './style.module.css'

const WallboardCard = ({
  width,
  mode,
  color,
  title,
  switchHandle,
  switchChecked,
  children,
  unifiedCard,
  channel
}) => {
  const defineCardClass = () => {
    if (width < 100) {
      if (mode === 'aside') return style.smallCardAside
      return style.smallCard
    } else if (mode === 'aside') {
      return style.cardAside
    }
    return style.card
  }

  const cardHeaderClass = () => {
    if (color === 'red') {
      return style.cardHeaderRed
    } else if (color === 'green') {
      return style.cardHeaderGreen
    } else if (color === 'purple') {
      return style.cardHeaderPurple
    } else if (mode === 'aside') {
      if (unifiedCard) {
        return channel === 1 ? style.phoneCardHeaderAsideUnified : style.cardHeaderAsideUnified
      }
      return style.cardHeaderAside
    }
    return style.cardHeader
  }

  const renderIcon = title => {
    if (mode === 'aside') {
      switch (title) {
        case 'Telefone':
        case '3cx e Twilio':
          return <FontAwesomeIcon className={style.titleIcon} icon={faPhone} />
        case 'Escritos':
        case 'Chat':
        case 'Chat e Whatsapp':
          return <FontAwesomeIcon className={style.titleIcon} icon={faCommentDots} />
        case 'WhatsApp':
          return <FontAwesomeIcon className={style.titleIcon} icon={faWhatsapp} />
        case 'Email':
          return <FontAwesomeIcon className={style.titleIcon} icon={faEnvelope} />
        default:
          return null
      }
    }
    return null
  }

  return (
    <div
      id="wallboard-card"
      style={{
        width: `${width}%`,
        display: `${width < 100 ? 'inline-block' : 'unset'}`,
        float: `${width < 100 ? 'left' : 'unset'}`
      }}
    >
      <div className={defineCardClass()}>
        <div className={cardHeaderClass()}>
          <div className={style.cardHeaderTitle}>
            {renderIcon(title)}
            {mode === 'aside' ? (
              <span className={switchHandle ? style.titleWithSwitch : null}>
                {title}
                {switchHandle && (
                  <Switch
                    showText={false}
                    checked={switchChecked}
                    onChange={switchHandle}
                    darkTheme
                  />
                )}
              </span>
            ) : (
              <span style={{ margin: '0 auto' }}>{title}</span>
            )}
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default WallboardCard
