import axios from 'axios'

import { getRefreshedToken } from '../helpers/AuthHelpers'

axios.interceptors.response.use(
  response => response,
  async function(error) {
    if (error.response && error.response.status === 401) {
      try {
        const refreshToken = localStorage.getItem('refreshToken')

        if (refreshToken) {
          const refreshed = await getRefreshedToken()

          if (refreshed) {
            const refreshedToken = localStorage.getItem('token')

            error.config.headers = {
              ...error.config.headers,
              token: refreshedToken
            }
            error.config.data = { access_token: refreshed }

            return axios(error.config)
          }
        }
        return Promise.reject(error)
      } catch (err) {
        return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)

export const runGetRequest = (url, params, headers, responseType, timeout) => {
  return new Promise((resolve, reject) => {
    axios.defaults.withCredentials = false

    axios
      .get(url, { params, headers, responseType, timeout })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        if (err?.response?.status === 408) {
          reject({ errorMessage: err.response.data?.message })
        } else {
          reject(err)
        }
      })
  })
}

export const runPostRequest = (url, body, headers, params, responseType) => {
  return new Promise((resolve, reject) => {
    axios.defaults.withCredentials = false

    axios
      .post(url, body, { params, headers, responseType })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err.response)
      })
  })
}
