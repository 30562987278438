export const isFilledArray = val => val && Array.isArray(val) && val.length

export const isObjectWithProps = val => val && typeof val === 'object' && Object.keys(val).length

export const filterBySquadNameOrLeaderFn = inputedSearchValue => ({
  nome_do_lider_de_operacao__c,
  squadName
}) =>
  (nome_do_lider_de_operacao__c &&
    nome_do_lider_de_operacao__c
      .toLowerCase()
      .replace(' | stone', '')
      .includes(inputedSearchValue)) ||
  (squadName && squadName.toLowerCase().includes(inputedSearchValue))

export const capitalizeStr = str => str.charAt(0).toUpperCase() + str.slice(1)
