import _ from 'lodash'
import moment from 'moment'
import { isObjectWithProps } from './GenericHelper'

export const formatPercentData = data => {
  return data || data === 0 ? `${_.round(data, 2)}%` : '-'
}

export const formatTimeData = data => {
  return data || data === 0
    ? data > 300
      ? `${_.round(data / 60, 2)}m`
      : `${_.round(data, 2)}s`
    : '-'
}

export const filterShifts = (filters, shift) => {
  const now = moment()
  const currentHour = now.hour()
  const currentMinute = (now.format('mm') >= '30' ? 30 : 0).toString().padStart(2, '0')

  return shift.filter(s => filters.includes(s[`h_${currentHour}_${currentMinute}`]))
}

export const getTwilioMetricKey = (source, metricName, prop = 'emails') =>
  _.flatten(source.filter(({ metric }) => metric === metricName).map(({ [prop]: key }) => key))

export const getTcxMetricsForView = (view, workforceMembers, activeCalls, loggedInExtensions) => {
  const currentViewMemberExtensions = []

  if (isObjectWithProps(workforceMembers)) {
    const membersEntries = Object.entries(workforceMembers['by_extension'])

    for (const [extension, { perfil__c, planning_department__c }] of membersEntries) {
      if (
        view.profiles.includes(perfil__c) ||
        view.planning_departments.includes(planning_department__c)
      ) {
        currentViewMemberExtensions.push(extension)
      }
    }
  }

  let loggedIn = 0
  let queueSize = 0
  let activeSize = 0

  if (isObjectWithProps(loggedInExtensions)) {
    for (const extension of currentViewMemberExtensions) {
      if (extension in loggedInExtensions['at_view']) {
        loggedIn++
      }
    }
  }

  if (isObjectWithProps(activeCalls)) {
    for (const { id } of view.queues) {
      if (id in activeCalls) {
        queueSize += activeCalls[id].length
      }
    }

    for (const extension of currentViewMemberExtensions) {
      if (extension in activeCalls) {
        activeSize += activeCalls[extension].length
      }
    }
  }

  return { loggedIn, queueSize, activeSize }
}
