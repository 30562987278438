const initialState = {
  isLoading: false,
  socketInformationTrigger: false
}

function globalReducer(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_LOADER':
      return { ...state, isLoading: action.isLoading }
    case 'SOCKET_INFORMATION_TRIGGER':
      return { ...state, socketInformationTrigger: !state.socketInformationTrigger }
    default:
      return state
  }
}

export default globalReducer
