import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import style from './style.module.css'

function GlobalLoader(props) {
  const defineClassName = () => {
    if (props.isLoading) {
      return style['is-loading']
    }
    return style.invisible
  }

  return (
    <div className={defineClassName()}>
      <svg className={style.spinner} width="65px" height="65px" viewBox="0 0 66 66">
        <circle className={style.circle} fill="none" strokeLinecap="round" cx="33" cy="33" r="30" />
      </svg>
    </div>
  )
}

GlobalLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return { ...state.globalReducer }
}

export default connect(mapStateToProps)(GlobalLoader)
