import WallboardServices from '../services/WallboardServices'

export const getToken = (shouldValidate = true) => {
  let token = window.localStorage.getItem('token')

  if (token && shouldValidate) {
    const loginMode = token.split(';')[0]

    if (loginMode === 'azure') {
      WallboardServices.getOauthAzureValidation(token.split(';')[2]).then(data => {
        token = `azure;${data.email};${data.validatedToken}`
      })
    }
  }

  return token
}

export const getRefreshedToken = () => {
  return new Promise(async resolve => {
    const refreshToken = window.localStorage.getItem('refreshToken')

    if (refreshToken) {
      const { data } = await WallboardServices.getRefreshedOauthAzureToken(refreshToken)

      if (data.access_token) {
        const accessToken = data.access_token
        const newToken = `azure;${localStorage.getItem('userEmail')};${accessToken}`

        localStorage.setItem('token', newToken)
        localStorage.setItem('refreshToken', data.refresh_token)

        resolve(accessToken)
      }
    }

    resolve(null)
  })
}

export const clearAllStorage = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('userEmail')
  localStorage.removeItem('userView')
  localStorage.removeItem('selectedView')
}

export const handleUnauthorizedResponse = (history, e) => {
  if (e.response && e.response.status === 401) {
    history.push('/')
  }
}
