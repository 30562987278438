import io from 'socket.io-client'
import _ from 'lodash'

import * as AuthHelper from '../../core/helpers/AuthHelpers'

import store from '../../redux/createStore'
import appConfig from './appConfig'

let activeChannels = []

export let socketInformation = {}

let retries = 3

// Keep alive connect by resending authentication
const reAuthInterval = () => {
  setInterval(() => {
    console.log('RE AUTHENTICATE > ', socket.id, 'ROOMS', activeChannels)

    if (socket.id) {
      socket.emit('reAuthenticate', { token: AuthHelper.getToken() })
    }
  }, 10 * 60 * 1000)
}

export let socket

export const connectOnSocket = async () => {
  if (socket && socket.id) {
    socket.disconnect()
    activeChannels = []
  }

  socket = io(appConfig.wallboardSocketURL, {
    reconnectionDelayMax: 30000,
    reconnectionAttempts: 5,
    reconnectionDelay: 5000,
    reconnection: true,
    transports: ['websocket'],
    auth: { token: AuthHelper.getToken() }
  })

  socket.on('connect', () => {
    socket.sendBuffer = []

    reAuthInterval()
    reconnectToPastActiveChannels()
  })

  socket.on('disconnect', reason => {
    console.log('Socket desconectada', reason)
    if (['io server disconnect'].includes(reason)) {
      window.location.reload()
    }
  })

  socket.on('disconnectReason', reason => {
    if (['Unauthorized'].includes(reason)) {
      window.location.href = '/'
    }
  })

  socket.on('connect_error', async data => {
    console.log('conected! > Erro de conexão na socket:', data)
    const token = await AuthHelper.getRefreshedToken()

    if (!token || retries <= 0) {
      window.location.href = '/'
    } else {
      retries -= 1
      connectOnSocket()
    }
  })

  socket.onAny((eventName, data) => {
    socketInformation[eventName] = data

    store.dispatch({ type: 'SOCKET_INFORMATION_TRIGGER' })
  })

  await socket
}

export const enterSocketChannel = (channelName, desiredViewsList = []) => {
  console.log('ENTER CHANNEL >>> ', socket.id, channelName, desiredViewsList)

  const desiredViews = _.orderBy(desiredViewsList).join(',')

  storeActiveChannel(channelName, desiredViews)

  if (socket.id) {
    socket.emit(channelName, { desiredViews: encodeURI(desiredViews) })
  }
}

export const disconectSocketChannel = (channelName, desiredViewsList = []) => {
  console.log('LEAVE CHANNEL >>> ', socket.id, channelName, desiredViewsList)

  const desiredViews = _.orderBy(desiredViewsList).join(',')

  removeActiveChannel(channelName, desiredViews)

  if (socket.id) {
    socket.emit('disconnectRoom', { channelName, desiredViews: encodeURI(desiredViews) })
  }
}

const getCurrentActiveChannelIdx = (searchingChannelName, searchingDesiredViews) => {
  return activeChannels.findIndex(element => {
    const { channelName, desiredViews } = element

    return channelName === searchingChannelName && desiredViews === searchingDesiredViews
  })
}

const storeActiveChannel = (channelName, desiredViews) => {
  const desiredChannelIdx = getCurrentActiveChannelIdx(channelName, desiredViews)

  if (desiredChannelIdx === -1) {
    activeChannels.push({ channelName, desiredViews })
  }
}

const removeActiveChannel = (channelName, desiredViews) => {
  const desiredChannelIdx = getCurrentActiveChannelIdx(channelName, desiredViews)

  if (desiredChannelIdx !== -1) {
    activeChannels.splice(desiredChannelIdx, 1)
  }
}

const reconnectToPastActiveChannels = () => {
  activeChannels.forEach(activeChannel => {
    const { channelName, desiredViews } = activeChannel

    enterSocketChannel(channelName, desiredViews.split(','))
  })
}
