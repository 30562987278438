import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'

import GlobalLoader from '../components/GlobalLoader'
import Login from '../pages/Login'
import Logout from '../pages/Logout'
import GeneralWallboard from '../pages/GeneralWallboard'
import OperationFollowup from '../pages/OperationFollowup'
import WallboardCSI from '../pages/WallboardCSI'
import TwilioAgents from '../pages/TwilioAgents'
import RCMonitoring from '../pages/RCMonitoring'
import RealXPlan from '../pages/RealXPlan'
import TMI from '../pages/TMI'
import TMA from '../pages/TMA'
import NocSubjects from '../pages/NocSubjects'
import NocBanks from '../pages/NocBanks'
import NocIncidents from '../pages/NocIncidents'
import Shifts from '../pages/Shifts'
import BlacklistedProfiles from '../pages/BlacklistedProfiles'
import HealthCheck from '../pages/HealthCheck'

export default (
  <BrowserRouter>
    <div>
      <GlobalLoader />
      <Route exact path="/" component={Login} />
      <Route path="/logout" component={Logout} />
      <Route path="/general-wallboard" component={GeneralWallboard} />
      <Route exact path="/operation-followup" component={OperationFollowup} />
      <Route path="/csi" component={WallboardCSI} />
      <Route path="/twilio-agents" component={TwilioAgents} />
      <Route path="/rc-monitoring" component={RCMonitoring} />
      <Route path="/real-x-plan" component={RealXPlan} />
      <Route path="/tmi" component={TMI} />
      <Route path="/tma" component={TMA} />
      <Route path="/noc-subjects" component={NocSubjects} />
      <Route path="/noc-banks" component={NocBanks} />
      <Route path="/noc-incidents" component={NocIncidents} />
      <Route path="/shifts" component={Shifts} />
      <Route path="/blacklisted-profiles" component={BlacklistedProfiles} />
      <Route path="/healthz" component={HealthCheck} />
      <Route path="*">
        {window.location.pathname !== '/' ? (window.location.href = '/') : null}
      </Route>
    </div>
  </BrowserRouter>
)
