import * as axiosWrapper from './axiosRequests'

import appConfig from './appConfig'

export default class WallboardServices {
  static getOauthAzureConfigurations() {
    const url = `${appConfig.wallboardApiUrl}/oauth/azure/configurations`

    return axiosWrapper.runGetRequest(url)
  }

  static getOauthAzureToken(code) {
    return new Promise(resolve => {
      const url = `${appConfig.wallboardApiUrl}/oauth/azure/token`

      axiosWrapper
        .runPostRequest(url, {
          code,
          redirect_uri: `${window.location.origin}/`
        })
        .then(res =>
          resolve({ accessToken: res.data.access_token, refreshToken: res.data.refresh_token })
        )
        .catch(err => resolve({ err }, window.location.reload()))
    })
  }

  static async getRefreshedOauthAzureToken(refreshToken) {
    const url = `${appConfig.wallboardApiUrl}/oauth/azure/token`

    return axiosWrapper.runPostRequest(url, {
      refresh_token: refreshToken,
      should_refresh: true,
      redirect_uri: `${window.location.origin}/`
    })
  }

  static getOauthAzureValidation(accessToken) {
    return new Promise(resolve => {
      const url = `${appConfig.wallboardApiUrl}/oauth/azure/validate_user`

      axiosWrapper
        .runPostRequest(url, { access_token: accessToken })
        .then(res =>
          resolve({ valid: res.data.valid, validatedToken: res.data.token, email: res.data.email })
        )
        .catch(err => resolve({ valid: false }, window.location.reload()))
    })
  }

  static getWallboardViewsComplete(identifiers) {
    const url = `${appConfig.wallboardApiUrl}/views_complete`

    return axiosWrapper.runGetRequest(url, identifiers, {
      token: window.localStorage.getItem('token')
    })
  }

  static getShiftsForDate(date, desiredViews) {
    const url = `${appConfig.wallboardApiUrl}/shifts_detailed`

    return axiosWrapper.runGetRequest(
      url,
      { date: date, desired_views: desiredViews },
      { token: window.localStorage.getItem('token') }
    )
  }

  static getUserMember(identification, mode) {
    const url = `${appConfig.wallboardApiUrl}/workforce_manager/user_member`
    const params = { ...identification, mode }

    return axiosWrapper.runGetRequest(url, params, { token: window.localStorage.getItem('token') })
  }
}
