import _ from 'lodash'

export const storeUserView = (userView, redirect, updateViewCb) => {
  const storedView = localStorage.getItem('userView')

  const formattedStoredView = storedView ? JSON.parse(storedView) : null

  const isSameView = _.isEqual(formattedStoredView, userView)

  if (userView.values.is_isolated || !(formattedStoredView && isSameView)) {
    const formattedUserView = JSON.stringify(userView)

    window.localStorage.setItem('userView', formattedUserView)
    window.localStorage.setItem('selectedView', formattedUserView)

    updateViewCb(userView)
  }

  redirect('/general-wallboard')
}
