import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import moment from 'moment'
import _ from 'lodash'

import SquadFilters from '../../components/SquadFilters'
import WallboardCard from '../../components/WallboardCard'
import WallboardCSITable from '../../components/WallboardCard/WallboardCSITable'
import SideBar from '../../components/SideBar'
import ChannelFilters from '../../components/ChannelFilters'

import * as socketService from '../../core/services/socketService'

import {
  filterBySquadNameOrLeaderFn,
  isFilledArray,
  isObjectWithProps
} from '../../core/helpers/GenericHelper'

import style from './style.module.css'

class WallboardCSI extends Component {
  state = {
    missingMembers: [],
    extraMembers: [],
    okMembers: [],
    turnedMembers: [],
    selectedInputValue: undefined,
    radioFilterValue: undefined
  }

  channelMap = {
    1: 'voice',
    2: 'chat',
    3: 'email',
    4: 'whatsapp',
    5: 'escritos'
  }

  socketRoom = 'csi'

  componentDidMount() {
    const { enterSocketChannel } = socketService
    const { selectedView } = this.props
    this._isMounted = true

    if (selectedView) {
      enterSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  componentDidUpdate(prevProps) {
    const { disconectSocketChannel, enterSocketChannel } = socketService
    const { socketInformationTrigger, selectedView } = this.props

    if (isObjectWithProps(selectedView)) {
      const { name: viewName } = selectedView
      const { selectedView: prevSelectedView } = prevProps

      if (!isObjectWithProps(prevSelectedView) || prevSelectedView.name !== viewName) {
        if (isObjectWithProps(prevSelectedView)) {
          disconectSocketChannel(this.socketRoom, [prevSelectedView.name])
        }

        enterSocketChannel(this.socketRoom, [viewName])
      }
    }

    if (prevProps.socketInformationTrigger !== socketInformationTrigger) {
      this.updateData()
    }
  }

  componentWillUnmount() {
    const { disconectSocketChannel } = socketService
    const { selectedView } = this.props
    this._isMounted = false

    if (selectedView) {
      disconectSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  updateData = _.throttle(() => {
    const {
      agentHalfHourlyMetrics,
      loggedInExtensions,
      omniAgentsActivities,
      shiftsDetailed,
      twilioWorkers,
      twilioMetrics,
      workforceMembers
    } = socketService.socketInformation

    const now = moment()
    const currentHour = now.hour()
    const currentMinute = now.format('mm') >= '30' ? 30 : 0

    const filteredShifts =
      shiftsDetailed &&
      shiftsDetailed.filter(shift =>
        ['A', 'C', 'CB', 'T'].includes(
          shift[`h_${currentHour}_${currentMinute.toString().padStart(2, '0')}`]
        )
      )

    this._isMounted &&
      this.setState(
        {
          workforceMembers,
          loggedInExtensions: loggedInExtensions && loggedInExtensions['joined'],
          agentHalfHourlyMetrics,
          twilioWorkers: twilioWorkers && twilioWorkers['joined']['Disponível'],
          twilioMetrics,
          shiftsDetailed: filteredShifts && _.keyBy(filteredShifts, 'memberId'),
          omniAgentsActivities: omniAgentsActivities && omniAgentsActivities['Disponível']
        },
        () => this.calculateCSI()
      )
  }, 2000)

  getFirstItemFromData = (obj, key) =>
    isObjectWithProps(obj) && isFilledArray(obj[key]) ? obj[key][0] : undefined

  runThroughShiftedMembers = () => {
    const { selectedView } = this.props
    const { workforceMembers, shiftsDetailed, twilioMetrics } = this.state

    const okMembers = []
    const missingMembers = []
    const turnedMembers = []

    const emailsLoggedInCurrentViewTwilioQueues = twilioMetrics
      ? _.flattenDeep(
          Object.values(twilioMetrics).map(v =>
            v
              .filter(({ metric }) => metric === 'agents_email_available_in_queue')
              .map(({ emails }) => emails)
          )
        )
      : []

    const viewProfiles =
      isObjectWithProps(selectedView) &&
      isObjectWithProps(selectedView.values) &&
      isFilledArray(selectedView.values.profiles)
        ? [...selectedView.values.profiles, selectedView.name].map(p => _.toLower(p))
        : [_.toLower(selectedView.name)]

    const shiftsEntries = Object.entries(shiftsDetailed)

    for (const [memberId, { channelId }] of shiftsEntries) {
      const memberInfo = workforceMembers['by_id'][memberId]

      if (!memberInfo) {
        continue
      }

      const {
        email,
        nome_do_lider_de_operacao__c,
        nome_mesa__c: squadName,
        outlook_name: memberName,
        tcx_extension
      } = memberInfo

      const shiftChannel = this.channelMap[channelId === 2 ? 5 : channelId]

      const { tcxInfo, twilioInfo, omniInfo, answeredInformation } = this.getMultipleChannelInfo(
        email,
        tcx_extension
      )

      const currentChannels = []

      let currentTeam = ''

      if (tcxInfo) {
        currentChannels.push('voice')
      }

      if (omniInfo) {
        const omniProfile = omniInfo.profile_name

        currentChannels.push('email')

        if (!viewProfiles.includes(_.toLower(omniProfile))) {
          currentTeam = omniProfile
        }
      }

      if (twilioInfo) {
        const { channels, teams } = twilioInfo

        currentChannels.push(...channels)

        if (
          !emailsLoggedInCurrentViewTwilioQueues.includes(email) &&
          !viewProfiles.includes(_.toLower(teams[0]))
        ) {
          currentTeam = teams[0]
        }
      }

      const shiftedChannels = [shiftChannel]

      if (!tcxInfo && !twilioInfo && !omniInfo) {
        missingMembers.push({
          memberName,
          squadName,
          shiftedChannels,
          assignedTasks: '-',
          capacity: '-',
          answeredInformation,
          nome_do_lider_de_operacao__c
        })
      } else if (currentTeam !== '') {
        turnedMembers.push({
          memberName,
          squadName,
          shiftedChannels,
          currentTeam,
          currentChannels,
          answeredInformation,
          nome_do_lider_de_operacao__c,
          assignedTasks: twilioInfo ? twilioInfo.assigned_tasks : '-',
          capacity: twilioInfo ? twilioInfo.capacity : '-'
        })
      } else {
        const channelsBesideShifted = _.difference(currentChannels, [shiftChannel])

        if (
          channelsBesideShifted.length &&
          (shiftChannel !== 'escritos' ||
            _.difference(channelsBesideShifted, ['chat', 'whatsapp']).length !== 0)
        ) {
          turnedMembers.push({
            memberName,
            squadName,
            shiftedChannels,
            currentChannels,
            answeredInformation,
            nome_do_lider_de_operacao__c,
            assignedTasks: twilioInfo ? twilioInfo.assigned_tasks : '-',
            capacity: twilioInfo ? twilioInfo.capacity : '-'
          })
        } else {
          okMembers.push({
            memberName,
            squadName,
            nome_do_lider_de_operacao__c,
            currentChannels
          })
        }
      }
    }

    return { okMembers, missingMembers, turnedMembers }
  }

  getMemberInfoWithValidation = (key, memberId) => {
    const { workforceMembers, shiftsDetailed } = this.state

    const memberInfo = workforceMembers[key][memberId]

    const obj = { valid: false, memberInfo }

    if (isObjectWithProps(memberInfo) && !shiftsDetailed[memberInfo.member_id]) {
      obj.valid = true
    }

    return obj
  }

  getExtraMemberAppendable = (memberInfo, currentChannels, answeredInformation, twilioInfo) => {
    const {
      nome_do_lider_de_operacao__c,
      nome_mesa__c: squadName,
      outlook_name: memberName,
      email
    } = memberInfo

    const { assigned_tasks: assignedTasks, capacity } = twilioInfo || {}

    return {
      email,
      memberName,
      squadName,
      currentChannels,
      answeredInformation,
      nome_do_lider_de_operacao__c,
      assignedTasks,
      capacity
    }
  }

  getMultipleChannelInfo = (memberEmail, memberExtension) => {
    const {
      loggedInExtensions,
      omniAgentsActivities,
      twilioWorkers,
      agentHalfHourlyMetrics
    } = this.state

    const currentHourDemands =
      agentHalfHourlyMetrics &&
      (agentHalfHourlyMetrics[memberEmail] || []).concat(
        agentHalfHourlyMetrics[memberExtension] || []
      )

    const answeredInformation = isFilledArray(currentHourDemands)
      ? _.sumBy(currentHourDemands, 'total_received')
      : 0

    const tcxInfo = this.getFirstItemFromData(loggedInExtensions, memberExtension)
    const twilioInfo = this.getFirstItemFromData(twilioWorkers, memberEmail)
    const omniInfo = this.getFirstItemFromData(omniAgentsActivities, memberEmail)

    return { answeredInformation, tcxInfo, twilioInfo, omniInfo }
  }

  pushExtraMember = (extraMembersArray, extraMember) => {
    if (!extraMembersArray.find(({ email }) => extraMember.email === email)) {
      extraMembersArray.push(extraMember)
    }
  }

  buildExtraMembers = () => {
    const { loggedInExtensions, omniAgentsActivities, twilioWorkers } = this.state

    const extraMembers = []

    if (isObjectWithProps(twilioWorkers)) {
      const twilioEntries = Object.entries(twilioWorkers)

      for (const [emailKey, [twilioInfo]] of twilioEntries) {
        const { valid, memberInfo } = this.getMemberInfoWithValidation('by_email', emailKey)

        if (!valid) {
          continue
        }

        const { email, tcx_extension } = memberInfo

        const { tcxInfo, omniInfo, answeredInformation } = this.getMultipleChannelInfo(
          email,
          tcx_extension
        )

        const currentChannels = twilioInfo.channels

        if (tcxInfo) {
          currentChannels.push('voice')
        }

        if (omniInfo) {
          currentChannels.push('email')
        }

        const appendable = this.getExtraMemberAppendable(
          memberInfo,
          currentChannels,
          answeredInformation,
          twilioInfo
        )

        this.pushExtraMember(extraMembers, appendable)
      }
    }

    if (isObjectWithProps(omniAgentsActivities)) {
      const omniKeys = Object.keys(omniAgentsActivities)

      for (const emailKey of omniKeys) {
        const { valid, memberInfo } = this.getMemberInfoWithValidation('by_email', emailKey)

        if (!valid) {
          continue
        }

        const { email, tcx_extension } = memberInfo

        const { tcxInfo, twilioInfo, answeredInformation } = this.getMultipleChannelInfo(
          email,
          tcx_extension
        )

        const currentChannels = ['email']

        if (tcxInfo) {
          currentChannels.push('voice')
        }

        if (twilioInfo) {
          currentChannels.push(...twilioInfo.channels)
        }

        const appendable = this.getExtraMemberAppendable(
          memberInfo,
          currentChannels,
          answeredInformation,
          twilioInfo
        )

        this.pushExtraMember(extraMembers, appendable)
      }
    }

    if (isObjectWithProps(loggedInExtensions)) {
      const tcxExtensions = Object.keys(loggedInExtensions)

      for (const extension of tcxExtensions) {
        const { valid, memberInfo } = this.getMemberInfoWithValidation('by_extension', extension)

        if (!valid) {
          continue
        }

        const { email, tcx_extension } = memberInfo

        const { omniInfo, twilioInfo, answeredInformation } = this.getMultipleChannelInfo(
          email,
          tcx_extension
        )

        const currentChannels = ['voice']

        if (omniInfo) {
          currentChannels.push('email')
        }

        if (twilioInfo) {
          currentChannels.push(...twilioInfo.channels)
        }

        const appendable = this.getExtraMemberAppendable(
          memberInfo,
          currentChannels,
          answeredInformation,
          twilioInfo
        )

        this.pushExtraMember(extraMembers, appendable)
      }
    }

    return extraMembers
  }

  calculateCSI = () => {
    const { selectedInputValue, shiftsDetailed, workforceMembers, radioFilterValue } = this.state

    if (workforceMembers && shiftsDetailed) {
      const extraMembers = this.buildExtraMembers()
      const { okMembers, missingMembers, turnedMembers } = this.runThroughShiftedMembers()

      if (selectedInputValue || radioFilterValue !== undefined) {
        this._isMounted &&
          this.setState({
            missingMembers: _.sortBy(this.filteringFunction(missingMembers), ['squadName']),
            extraMembers: this.filteringFunction(extraMembers),
            okMembers: this.filteringFunction(okMembers),
            turnedMembers: this.filteringFunction(turnedMembers)
          })
      } else {
        this._isMounted &&
          this.setState({
            missingMembers: _.sortBy(missingMembers, ['squadName']),
            extraMembers,
            okMembers,
            turnedMembers
          })
      }
    }
  }

  handleSearch = event =>
    this._isMounted &&
    this.setState({ selectedInputValue: event.target.value }, () => this.calculateCSI())

  handleFilter = event =>
    this._isMounted &&
    this.setState({ radioFilterValue: event.target.value }, () => this.calculateCSI())

  filteringFunction = (membersList, onlyShifted = false, onlyCurrent = false) => {
    const { selectedInputValue, radioFilterValue } = this.state

    const { 2: chat, 4: whatsapp, 5: written } = this.channelMap

    const writtenChannels = [chat, whatsapp, written]

    const desiredList = !radioFilterValue
      ? membersList
      : radioFilterValue === written
      ? membersList.filter(
          ({ currentChannels: channels, shiftedChannels: sChannels }) =>
            (!onlyShifted &&
              isFilledArray(channels) &&
              channels.find(c => writtenChannels.includes(c))) ||
            (!onlyCurrent &&
              isFilledArray(sChannels) &&
              sChannels.find(c => writtenChannels.includes(c)))
        )
      : membersList.filter(
          ({ currentChannels: channels, shiftedChannels: sChannels }) =>
            (!onlyShifted &&
              isFilledArray(channels) &&
              channels.find(c => c === radioFilterValue)) ||
            (!onlyCurrent &&
              isFilledArray(sChannels) &&
              sChannels.find(c => c === radioFilterValue))
        )

    if (selectedInputValue) {
      const lowerCaseInput = _.toLower(selectedInputValue)

      return desiredList.filter(filterBySquadNameOrLeaderFn(lowerCaseInput))
    }

    return desiredList
  }

  render = () => {
    const { extraMembers, missingMembers, okMembers, turnedMembers } = this.state

    return (
      <>
        <SideBar />

        <div id="general-wallboard">
          <div className={style.generalWallboardCSI}>
            <div className={style.captionWrapper}>
              <span>AS = ATENDIMENTOS SIMULTÂNEOS </span>
              <span>CP = CAPACIDADE </span>
              <span>CE = CANAL ESPERADO </span>
              <span>R = RECEBIDAS NA MEIA HORA ATUAL </span>
              <span>TA = TIME ATENDENDO </span>
            </div>

            <div className={style.filterWrapper}>
              <SquadFilters handleSearch={this.handleSearch} />
              <ChannelFilters handleFilter={this.handleFilter} />
            </div>

            <div>
              <WallboardCard
                width={50}
                title={`Agentes Logados No(s) Canal(is) - ${extraMembers.length +
                  okMembers.length +
                  this.filteringFunction(turnedMembers, false, true).length}`}
              />
              <WallboardCard
                width={50}
                title={`Agentes Escalados Para o(s) Canal(is) - ${missingMembers.length +
                  okMembers.length +
                  this.filteringFunction(turnedMembers, true).length}`}
              />
              <WallboardCard
                width={33}
                title={`Agentes Faltantes - ${missingMembers.length}`}
                color="red"
              >
                <WallboardCSITable data={missingMembers} missingAgents />
              </WallboardCard>
              <WallboardCard
                width={33}
                title={`Agentes Virados - Do(s) Canal(is) -> ${
                  this.filteringFunction(turnedMembers, true).length
                } - Para o(s) Canal(is) -> ${
                  this.filteringFunction(turnedMembers, false, true).length
                }`}
                color="purple"
              >
                <WallboardCSITable data={turnedMembers} turnedAgents />
              </WallboardCard>
              <WallboardCard
                width={33}
                title={`Agentes Extras - ${extraMembers.length}`}
                color="green"
              >
                <WallboardCSITable data={extraMembers} />
              </WallboardCard>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ ...state.sideBarReducer, ...state.globalReducer })

export default connect(mapStateToProps)(withRouter(WallboardCSI))
