import _ from 'lodash'
import ChartIndex from './ChartIndex'

export default class ChartDataset {
  constructor(response, type, hasNSI = true) {
    this.labels = []
    let otherBanksTotal = {}

    let d21 = new ChartIndex('d-21', 'rgba(52, 152, 219, 0.7)', 'bar')
    let d14 = new ChartIndex('d-14', 'rgba(155, 89, 182, 0.7)', 'bar')
    let d7 = new ChartIndex('d-7', 'rgba(241, 196, 15, 0.7)', 'bar')
    let d0 = new ChartIndex('d-0', 'rgba(9, 170, 54, 0.7)', 'bar')
    let deviation = new ChartIndex('Desvio', 'rgba(241, 0, 15, 0.5)', 'line')

    let realized = new ChartIndex('Atendidas dentro do NS', 'rgba(9, 170, 54, 0.7)', 'bar')
    let planned = new ChartIndex('Atendidas fora do NS', 'rgba(241, 196, 15, 0.7)', 'bar')
    let serviceLevel = new ChartIndex('Nível de Serviço', 'rgba(241, 0, 15, 0.7)', 'line')
    const nsi = new ChartIndex('NSI V2', 'rgba(222, 59, 255, 0.7)', 'line', 'percentage-y-axis')

    switch (type) {
      case 'noc':
        realized = new ChartIndex('Realizado', 'rgba(9, 170, 54, 0.7)', 'bar')
        planned = new ChartIndex('Planejado', 'rgba(241, 196, 15, 0.7)', 'bar')
        response.forEach(analytic => {
          this.labels.push(
            `${analytic.hour}:${analytic.minute < 10 ? `0${analytic.minute}` : analytic.minute}`
          )
          planned.data.push(analytic.planned)
          realized.data.push(analytic.realized)
        })
        this.datasets = [realized, planned]
        break
      case 'minute-noc-with-service-level':
        realized = new ChartIndex('Realizado', 'rgba(9, 170, 54, 0.7)', 'bar')
        planned = new ChartIndex('Planejado', 'rgba(241, 196, 15, 0.7)', 'bar')
        serviceLevel = new ChartIndex(
          'Nível de Serviço',
          'rgba(241, 0, 15, 0.5)',
          'line',
          'percentage-y-axis'
        )

        response.forEach(analytic => {
          this.labels.push(
            `${analytic.hour}:${analytic.minute < 10 ? `0${analytic.minute}` : analytic.minute}`
          )
          planned.data.push(analytic.planned)
          realized.data.push(analytic.realized)
          serviceLevel.data.push(analytic.serviceLevel)
          nsi.data.push(analytic.nsi)
        })

        this.datasets = [
          {
            ...realized,
            datalabels: {
              color: '#006302',
              font: {
                size: 15,
                weight: 'bold'
              },
              align: 'top',
              anchor: 'center',
              rotation: -45,
              offset: 0
            }
          },
          {
            ...planned,
            datalabels: {
              font: {
                size: 15,
                weight: 'bold'
              },
              display: 'auto',
              align: 'top',
              anchor: 'end',
              rotation: -45,
              offset: 15
            }
          },
          serviceLevel
        ]

        if (hasNSI) {
          this.datasets.push(nsi)
        }

        break
      case 'tmi-with-service-level':
        realized = new ChartIndex('TMI V2 (segundos)', 'rgba(9, 170, 54, 0.7)', 'bar')
        serviceLevel = new ChartIndex(
          'Nível de Serviço',
          'rgba(241, 0, 15, 0.5)',
          'line',
          'percentage-y-axis'
        )

        response.forEach(analytic => {
          this.labels.push(
            `${analytic.hour}:${analytic.minute < 10 ? `0${analytic.minute}` : analytic.minute}`
          )
          realized.data.push(analytic.realized)
          serviceLevel.data.push(analytic.serviceLevel)
          nsi.data.push(analytic.nsi)
        })

        this.datasets = [
          {
            ...realized,
            datalabels: {
              color: '#006302',
              font: {
                size: 15,
                weight: 'bold'
              },
              align: 'top',
              anchor: 'center',
              rotation: -45,
              offset: 0
            }
          },
          serviceLevel,
          nsi
        ]
        break
      case 'tma-with-service-level':
        realized = new ChartIndex('TMA (minutos)', 'rgba(9, 170, 54, 0.7)', 'bar')
        serviceLevel = new ChartIndex(
          'Nível de Serviço',
          'rgba(241, 0, 15, 0.5)',
          'line',
          'percentage-y-axis'
        )

        response.forEach(analytic => {
          this.labels.push(`${analytic.hour}:${_.padStart(analytic.minute, 2, '0')}`)
          realized.data.push(
            analytic.realized ? (analytic.realized / 60).toFixed(2) : analytic.realized
          )
          serviceLevel.data.push(analytic.serviceLevel)
          nsi.data.push(analytic.nsi)
        })

        this.datasets = [
          {
            ...realized,
            datalabels: {
              color: '#006302',
              font: {
                size: 15,
                weight: 'bold'
              },
              align: 'top',
              anchor: 'center',
              rotation: -45,
              offset: 0
            }
          },
          serviceLevel
        ]

        if (hasNSI) {
          this.datasets.push(nsi)
        }

        break
      case 'noc-subjects':
        d21 = new ChartIndex('d-21', 'rgba(52, 152, 219, 0.7)', 'bar')
        d14 = new ChartIndex('d-14', 'rgba(155, 89, 182, 0.7)', 'bar')
        d7 = new ChartIndex('d-7', 'rgba(241, 196, 15, 0.7)', 'bar')
        d0 = new ChartIndex('d-0', 'rgba(9, 170, 54, 0.7)', 'bar')
        deviation = new ChartIndex(
          'Desvio',
          'rgba(241, 0, 15, 0.5)',
          'line',
          'noc-subjects-percent'
        )

        response.forEach(data => {
          this.labels.push([
            `${data.tema}`,
            `${data.categoria ? `${data.categoria}` : ''}`,
            `${data.assunto ? `${data.assunto}` : ''}`
          ])
          d21.data.push(data.d_21)
          d14.data.push(data.d_14)
          d7.data.push(data.d_7)
          d0.data.push(data.d_0)
          deviation.data.push(data.avg_adherence)
        })
        this.datasets = [d21, d14, d7, d0, deviation]
        break
      case 'noc-banks':
        d21 = new ChartIndex('d-21', 'rgba(52, 152, 219, 0.7)', 'bar')
        d14 = new ChartIndex('d-14', 'rgba(155, 89, 182, 0.7)', 'bar')
        d7 = new ChartIndex('d-7', 'rgba(241, 196, 15, 0.7)', 'bar')
        d0 = new ChartIndex('d-0', 'rgba(9, 170, 54, 0.7)', 'bar')
        deviation = new ChartIndex('Desvio', 'rgba(241, 0, 15, 0.5)', 'line', 'noc-banks-percent')

        d21.data = [0, 0, 0, 0]
        d14.data = [0, 0, 0, 0]
        d7.data = [0, 0, 0, 0]
        d0.data = [0, 0, 0, 0]
        deviation.data = [0, 0, 0, 0]

        otherBanksTotal = {
          d21: 0,
          d14: 0,
          d7: 0,
          d0: 0,
          deviation: 0
        }
        this.labels = [
          'Itaú Unibanco S.A.',
          'Banco Bradesco S.A.',
          'Banco Santander (Brasil) S.A.',
          'Caixa Econômica Federal',
          'Outros'
        ]
        response.forEach(data => {
          if (this.labels.indexOf(data.bank_name) > -1) {
            d21.data.splice(this.labels.indexOf(data.bank_name), 1, data.d_21)
            d14.data.splice(this.labels.indexOf(data.bank_name), 1, data.d_14)
            d7.data.splice(this.labels.indexOf(data.bank_name), 1, data.d_7)
            d0.data.splice(this.labels.indexOf(data.bank_name), 1, data.d_0)
            deviation.data.splice(this.labels.indexOf(data.bank_name), 1, data.avg_adherence)
          } else {
            otherBanksTotal = {
              d21: otherBanksTotal.d21 + data.d_21,
              d14: otherBanksTotal.d14 + data.d_14,
              d7: otherBanksTotal.d7 + data.d_7,
              d0: otherBanksTotal.d0 + data.d_0
            }
          }
        })
        d21.data.push(otherBanksTotal.d21)
        d14.data.push(otherBanksTotal.d14)
        d7.data.push(otherBanksTotal.d7)
        d0.data.push(otherBanksTotal.d0)
        deviation.data.push(
          parseFloat(
            otherBanksTotal.d0 /
              ((otherBanksTotal.d21 + otherBanksTotal.d14 + otherBanksTotal.d7) / 3)
          ).toFixed(2)
        )

        this.datasets = [d21, d14, d7, d0, deviation]
        break
      case 'noc-incidents':
        d21 = new ChartIndex('d-21', 'rgba(52, 152, 219, 0.7)', 'bar')
        d14 = new ChartIndex('d-14', 'rgba(155, 89, 182, 0.7)', 'bar')
        d7 = new ChartIndex('d-7', 'rgba(241, 196, 15, 0.7)', 'bar')
        d0 = new ChartIndex('d-0', 'rgba(9, 170, 54, 0.7)', 'bar')
        deviation = new ChartIndex(
          'Desvio',
          'rgba(241, 0, 15, 0.5)',
          'line',
          'noc-incidents-percent'
        )

        this.labels = []

        response.forEach(data => {
          this.labels.push(data.incident_name)
          d21.data.splice(data.incident_name, 0, data.d_21)
          d14.data.splice(data.incident_name, 0, data.d_14)
          d7.data.splice(data.incident_name, 0, data.d_7)
          d0.data.splice(data.incident_name, 0, data.d_0)
          deviation.data.splice(data.incident_name, 0, data.avg_adherence)
        })

        this.datasets = [d21, d14, d7, d0, deviation]
        break
      default:
        this.labels = []
        this.datasets = []
    }
  }
}
