export default class UtilsFilters {
  static filterTop(data, slMax, field) {
    return data
      .sort((x, y) => {
        if (x[field] < y[field]) return 1
        if (x[field] > y[field]) return -1
        return 0
      })
      .slice(0, slMax)
  }
}
