const initialState = {
  isIsolatedView: undefined,
  selectedDepartment: '1',
  selectedView: undefined,
  channels: {
    phone: 1,
    chat: 2,
    email: 3
  },
  views: {},
  departments: {
    Cyber: 3,
    'Front Elavon': 2,
    'Front Stone': 1,
    Logística: 7,
    RAV: 4,
    RT: 6,
    Trading: 5
  },
  departmentIds: {
    1: 'Front Stone',
    2: 'Front Elavon',
    3: 'Cyber',
    4: 'RAV',
    5: 'Trading',
    6: 'RT',
    7: 'Logística'
  },
  squads: {}
}

function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_ISOLATED_VIEW':
      return { ...state, isIsolatedView: action.isIsolatedView }
    case 'SAVE_CHANNELS':
      return { ...state, channels: action.channels }
    case 'SAVE_VIEWS':
      return { ...state, views: action.views }
    case 'SAVE_DEPARTMENTS':
      return { ...state, departments: action.departments }
    case 'SAVE_DEPARTMENT_IDS':
      return { ...state, departmentIds: action.departmentIds }
    case 'UPDATE_SELECTED_DEPARTMENT':
      return { ...state, selectedDepartment: action.selectedDepartment }
    case 'UPDATE_SELECTED_VIEW':
      return { ...state, selectedView: action.selectedView }
    default:
      return state
  }
}

export default sidebarReducer
