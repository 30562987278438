import React, { Component } from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import { faAngleRight, faAngleLeft } from '@fortawesome/fontawesome-free-solid'

import WallboardCard from '../../components/WallboardCard'
import WallboardCardData from '../../components/WallboardCard/WallboardCardData'
import WallboardIncidentsData from '../../core/models/WallboardIncidentsData'
import OccurrenceNode from '../../core/models/OccurrenceNode'

import { isObjectWithProps, isFilledArray } from '../../core/helpers/GenericHelper'

import style from './style.module.css'

class IncidentsCard extends Component {
  state = {
    incidentsNode: [],
    incidentsRanking: [],
    selectedIndex: 0,
    showTopIncidents: true
  }

  componentDidMount() {
    this._isMounted = true

    this.refreshData()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.topIncidents !== this.props.topIncidents ||
      prevProps.activeIncidents !== this.props.activeIncidents
    ) {
      this.refreshData()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  refreshData = () => {
    const { activeIncidents, topIncidents } = this.props

    const incidentsNode = isFilledArray(activeIncidents)
      ? activeIncidents.map(d => new OccurrenceNode(d))
      : []
    const incidentsRanking = isObjectWithProps(topIncidents)
      ? topIncidents.top_3.map(d => new WallboardIncidentsData(d))
      : []

    this._isMounted && this.setState({ incidentsNode, incidentsRanking })
  }

  nodesLength = () => {
    return this.state.incidentsNode.length
  }

  goBack = () => {
    if (this.nodesLength()) {
      const prevIndex = this.state.selectedIndex - 1

      this._isMounted &&
        this.setState({ selectedIndex: prevIndex === -1 ? this.nodesLength() - 1 : prevIndex })
    }
  }

  goForward = () => {
    if (this.nodesLength()) {
      const nextIndex = this.state.selectedIndex + 1

      this._isMounted &&
        this.setState({ selectedIndex: nextIndex === this.nodesLength() ? 0 : nextIndex })
    }
  }

  handleTopIncidents = () => {
    this._isMounted && this.setState({ showTopIncidents: !this.state.showTopIncidents })
  }

  render = () => {
    const { incidentsNode, selectedIndex, showTopIncidents, incidentsRanking } = this.state
    const { mode } = this.props

    const cardTitle = showTopIncidents ? 'Top Incidentes' : 'Incidentes Ativos'

    return (
      <WallboardCard
        width={33.33}
        title={cardTitle}
        mode={mode}
        switchChecked={showTopIncidents}
        switchHandle={this.handleTopIncidents}
      >
        <div id="IncidentsCard" className={style.carrousell}>
          {showTopIncidents ? (
            <WallboardCardData isVertical hasTable={false} data={incidentsRanking} mode={mode} />
          ) : (
            [
              <div key={`a_${selectedIndex}`} className={style.carousellContents}>
                {incidentsNode[selectedIndex]}
              </div>,
              <div key={`b_${selectedIndex}`} className={style.carousellPagination}>
                <button onClick={this.goBack} className={style.carousellPaginationButton}>
                  <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <div className={style.carrousellPagesInfo}>
                  <span className={style.carousellPagesInfoSelectedpage}>
                    {this.nodesLength() ? selectedIndex + 1 : 0}
                  </span>{' '}
                  de {this.nodesLength()}
                </div>
                <button onClick={this.goForward} className={style.carousellPaginationButton}>
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
            ]
          )}
        </div>
      </WallboardCard>
    )
  }
}

export default IncidentsCard
