import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'

import SideBar from '../../components/SideBar'
import IncidentsCard from '../../components/IncidentsCard'
import PhoneSummary from '../../components/PhoneSummary'
import WrittenSummary from '../../components/WrittenSummary'
import EmailSummary from '../../components/EmailSummary'
import TopSubjects from '../../components/TopSubjects'

import * as socketService from '../../core/services/socketService'

import { isFilledArray, isObjectWithProps } from '../../core/helpers/GenericHelper'
import {
  filterShifts,
  formatPercentData,
  formatTimeData,
  getTwilioMetricKey
} from '../../core/helpers/ChannelSummaryHelper'

import style from './style.module.css'

class GeneralWallboard extends Component {
  state = {
    casesInQueues: undefined,
    dailyMetrics: undefined,
    nocIncidents: undefined,
    nocSubjects: undefined,
    shiftsDetailed: undefined,
    activeCalls: undefined,
    twilioIncomingActive: undefined,
    twilioMetrics: undefined,
    loggedInExtensions: undefined,
    workforceMembers: undefined
  }

  socketRoom = 'generalWallboard'

  channels = [
    { ids: [1], tools: ['3cx'], name: 'Telefone 3cx', queues: [] },
    { ids: [1], tools: ['Twilio'], name: 'Telefone Twilio', queues: [] },
    { ids: [1], tools: ['3cx', 'Twilio'], name: 'Telefone Unidos', queues: [] },
    { ids: [2], tools: ['Twilio'], name: 'Chat', queues: [] },
    { ids: [3], tools: ['Salesforce'], name: 'Email', queues: [] },
    { ids: [4], tools: ['Twilio'], name: 'WhatsApp', queues: [] },
    { ids: [2, 4], tools: ['Twilio'], name: 'Escritos', queues: [] }
  ]

  commonShifts = [
    {
      title: 'Escala',
      key: 'scheduled'
    },
    {
      title: 'Almoço',
      key: 'lunch'
    },
    {
      title: 'Treinamento',
      key: 'training'
    },
    {
      title: 'Reunião',
      key: 'meetings'
    },
    {
      title: 'Reserva',
      key: 'backup'
    }
  ]

  // manter na ordem de amostragem
  commonMetrics = [
    {
      title: 'Clientes na Fila',
      key: 'queueSize'
    },
    {
      title: 'Atendimentos Agora',
      key: 'activeSize'
    },
    {
      title: 'Total de Chamados',
      key: 'totalReceived'
    },
    {
      title: 'Nível de Serviço',
      key: 'serviceLevel',
      formatFn: formatPercentData
    },
    {
      title: '% Perdidas',
      key: 'missed',
      formatFn: formatPercentData
    },
    {
      title: 'TME',
      key: 'tme',
      formatFn: formatTimeData
    }
  ]

  componentDidMount() {
    const { selectedView } = this.props
    this._isMounted = true

    if (isObjectWithProps(selectedView)) {
      socketService.enterSocketChannel(this.socketRoom, [selectedView.name])
      this.defineChannelQueues()
    }
  }

  componentDidUpdate(prevProps) {
    const { disconectSocketChannel, enterSocketChannel } = socketService
    const { selectedView, socketInformationTrigger } = this.props

    if (isObjectWithProps(selectedView)) {
      const { name: viewName } = selectedView
      const { selectedView: prevSelectedView } = prevProps

      if (!isObjectWithProps(prevSelectedView) || prevSelectedView.name !== viewName) {
        if (isObjectWithProps(prevSelectedView)) {
          disconectSocketChannel(this.socketRoom, [prevSelectedView.name])
        }

        enterSocketChannel(this.socketRoom, [viewName])
        this.defineChannelQueues()
      }
    }

    if (prevProps.socketInformationTrigger !== socketInformationTrigger) {
      this.updateGeneralWallboard()
    }
  }

  componentWillUnmount() {
    const { selectedView } = this.props
    this._isMounted = false

    if (selectedView) {
      socketService.disconectSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  filterChannels = desiredChannels => {
    const { channels } = this

    return desiredChannels.map(desiredName => channels.find(({ name }) => name === desiredName))
  }

  defineChannelQueues = () => {
    const { channels, props } = this

    for (const channel of channels) {
      channel.queues = props.selectedView.values.queues
        .filter(({ channel_id }) => channel.ids.includes(channel_id))
        .map(({ id }) => id)
    }
  }

  updateGeneralWallboard = _.throttle(() => {
    const { socketInformation } = socketService

    if (isObjectWithProps(socketInformation)) {
      this._isMounted && this.setState({ ...socketInformation })
    }
  }, 2000)

  getChannelShifts = channelName => {
    const { shiftsDetailed } = this.state

    const channels = this.filterChannels([channelName])

    return channels.map(({ ids }) => {
      const channelShifts = {
        scheduled: 0,
        lunch: 0,
        training: 0,
        backup: 0,
        meetings: 0,
        scheduled_members: {}
      }

      if (isObjectWithProps(shiftsDetailed)) {
        const currentChannelsShifts = shiftsDetailed.filter(({ channelId }) =>
          ids.includes(channelId)
        )

        const currentShiftedMembers = filterShifts(['A', 'C', 'CB', 'T'], currentChannelsShifts)

        channelShifts.scheduled = currentShiftedMembers.length
        channelShifts.lunch = filterShifts(['AL'], currentChannelsShifts).length
        channelShifts.training = filterShifts(['TR'], currentChannelsShifts).length
        channelShifts.backup = filterShifts(['P'], currentChannelsShifts).length
        channelShifts.meetings = filterShifts(['R'], currentChannelsShifts).length
        channelShifts.scheduled_members = _.keyBy(currentShiftedMembers, 'memberId')
      }

      return channelShifts
    })
  }

  getTwilioMetricsByChannel = channelName => {
    const { twilioMetrics } = this.state

    const currentChannelsQueues = this.channels.find(ch => ch.name === channelName).queues

    const availableMembers = []
    const unavailableMembers = []

    if (isObjectWithProps(twilioMetrics)) {
      for (const queue of currentChannelsQueues) {
        const queueInfo = twilioMetrics[queue]

        if (queueInfo) {
          const available = getTwilioMetricKey(queueInfo, 'agents_email_available_in_queue')
          const unavailable = getTwilioMetricKey(queueInfo, 'agents_email_unavailable_in_queue')
          availableMembers.push(...available)
          unavailableMembers.push(...unavailable)
        }
      }
    }

    return [_.uniq(availableMembers), _.uniq(unavailableMembers)]
  }

  getDailyMetricsByChannel = channelNames => {
    const { dailyMetrics } = this.state

    const channels = this.filterChannels(channelNames)

    return channels.map(({ ids, tools }) => {
      const currentChannelsMetrics = isFilledArray(dailyMetrics)
        ? dailyMetrics.filter(
            ({ channel_id, tool, total_received }) =>
              ids.includes(channel_id) && tools.includes(tool) && total_received > 0
          )
        : []

      let totalReceived = 0
      let primarilyReceived = 0
      let missed = 0
      let serviceLevel = 0
      let nsi = 0
      let tme = 0
      let tmi = 0
      let tma = 0

      totalReceived = _.sumBy(currentChannelsMetrics, 'total_received')
      primarilyReceived = _.sumBy(currentChannelsMetrics, 'primarily_received')
      const totalMissed = _.sumBy(currentChannelsMetrics, 'missed')
      const answeredInTime = _.sumBy(currentChannelsMetrics, 'answered_in_time')
      const totalWaitingTime = _.sumBy(currentChannelsMetrics, 'total_waiting_time')
      const totalInteractionTime = _.sumBy(currentChannelsMetrics, 'total_interaction_time')
      const totalDuration = _.sumBy(currentChannelsMetrics, 'total_duration')
      const totalInteractionBlocks = _.sumBy(currentChannelsMetrics, 'total_interaction_blocks')
      const interactedInTime = _.sumBy(currentChannelsMetrics, 'interacted_in_time') || 0

      if (totalReceived > 0) {
        serviceLevel = (answeredInTime * 100) / totalReceived
        tme = totalWaitingTime / totalReceived
        tma = totalDuration / totalReceived
        missed = (totalMissed * 100) / totalReceived
      }

      if (totalInteractionBlocks > 0) {
        tmi = totalInteractionTime / totalInteractionBlocks
        nsi = (interactedInTime * 100) / totalInteractionBlocks
      }

      return { totalReceived, primarilyReceived, missed, serviceLevel, nsi, tme, tmi, tma }
    })
  }

  getTwilioMetricsForViewByChannel = channelId => {
    const { twilioIncomingActive } = this.state

    let activeSize = 0
    let queueSize = 0

    if (isObjectWithProps(twilioIncomingActive)) {
      for (const { id, channel_id: queueChannel } of this.props.selectedView.values.queues) {
        const queueInfo = twilioIncomingActive[id]

        if (queueChannel === channelId && queueInfo) {
          const ammountActive = _.sum(getTwilioMetricKey(queueInfo, 'active_chats', 'value'))
          const ammountQueueSize = _.sum(getTwilioMetricKey(queueInfo, 'incoming_chats', 'value'))
          activeSize += ammountActive
          queueSize += ammountQueueSize
        }
      }
    }

    return { activeSize, queueSize }
  }

  getFormatMetricsFn = (rawMetricsData, cards) =>
    cards.map(({ key, title, formatFn }) => {
      const rawValue = rawMetricsData[key]

      return { title, info: formatFn ? formatFn(rawValue) : rawValue }
    })

  getFormatShiftsFn = (rawShiftsData, cards = this.commonShifts) =>
    cards.map(({ title, key, className }) => ({
      title,
      className,
      info: rawShiftsData[key] || 0
    }))

  render = () => {
    const {
      incidents,
      nocIncidents,
      workforceMembers,
      activeCalls,
      loggedInExtensions,
      twilioIncomingActive,
      casesInQueues,
      nocSubjects
    } = this.state
    const { selectedView } = this.props

    const mode = 'aside'

    return (
      <>
        <SideBar />

        {selectedView ? (
          <div className={style.generalWallboard}>
            <div className={style.generalWallboardCaptionContainer}>
              <span>TMI - Tempo Médio entre Interações</span>
              <span>TMA - Tempo Médio de Atendimento</span>
              <span>TME - Tempo Médio de Espera</span>
            </div>
            <PhoneSummary
              mode={mode}
              view={selectedView}
              activeCalls={activeCalls}
              workforceMembers={workforceMembers}
              loggedInExtensions={loggedInExtensions}
              commonShifts={this.commonShifts}
              commonMetrics={this.commonMetrics}
              getChannelShifts={this.getChannelShifts}
              getTwilioMetricsForViewByChannel={this.getTwilioMetricsForViewByChannel}
              getDailyMetricsByChannel={this.getDailyMetricsByChannel}
              getTwilioMetricsByChannel={this.getTwilioMetricsByChannel}
              getFormatMetricsFn={this.getFormatMetricsFn}
              getFormatShiftsFn={this.getFormatShiftsFn}
            />
            <WrittenSummary
              mode={mode}
              view={selectedView}
              twilioIncomingActive={twilioIncomingActive}
              commonShifts={this.commonShifts}
              commonMetrics={this.commonMetrics}
              getChannelShifts={this.getChannelShifts}
              getTwilioMetricsForViewByChannel={this.getTwilioMetricsForViewByChannel}
              getDailyMetricsByChannel={this.getDailyMetricsByChannel}
              getTwilioMetricsByChannel={this.getTwilioMetricsByChannel}
              getFormatMetricsFn={this.getFormatMetricsFn}
              getFormatShiftsFn={this.getFormatShiftsFn}
            />
            <EmailSummary
              mode={mode}
              casesInQueues={casesInQueues}
              getDailyMetricsByChannel={this.getDailyMetricsByChannel}
              getFormatMetricsFn={this.getFormatMetricsFn}
            />
            <TopSubjects mode={mode} nocSubjects={nocSubjects} />
            <IncidentsCard
              topIncidents={nocIncidents}
              activeIncidents={incidents}
              view={selectedView}
              mode={mode}
            />
          </div>
        ) : null}
      </>
    )
  }
}

const mapStateToProps = state => ({ ...state.sideBarReducer, ...state.globalReducer })

export default connect(mapStateToProps)(withRouter(GeneralWallboard))
