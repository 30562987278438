import _ from 'lodash'
import moment from 'moment'
import { capitalizeStr } from './GenericHelper'

export const defineYTicks = (chartData, deviationIndex) => {
  if (!_.isEmpty(chartData)) {
    const maxValue = _.maxBy(chartData.datasets[deviationIndex].data, num => parseFloat(num)) * 1.05

    return {
      max: maxValue,
      min: 0,
      stepSize: maxValue / 10
    }
  }
  return {
    max: 1.2,
    min: 0,
    stepSize: 0.1
  }
}

export const formatServiceLevelValue = serviceLevel => Number(serviceLevel * 100).toFixed(2)

export const getEmptyChartData = () => {
  const date = moment().format('YYYY-MM-DD')
  const mobileDate = moment(`${date}T00:00:00`)
  const dataset = []

  _.times(24, () => {
    for (var minute of [0, 30]) {
      dataset.push({
        date,
        hour: mobileDate.hour(),
        minute
      })
    }
    mobileDate.add(1, 'hour')
  })

  return dataset
}

export const getShouldUpdateStr = stateKey => 'shouldUpdate' + capitalizeStr(stateKey)

export const handShakeFn = (stateKey, component) =>
  component.setState({ [getShouldUpdateStr(stateKey)]: false })
