import React from 'react'

function BlacklistedProfiles() {
  return (
    <div>
      <h1>Você não tem acesso ao Wallboard!</h1>
      <h1>Caso você precise desse acesso, comunique a sua liderança!</h1>
    </div>
  )
}

export default BlacklistedProfiles
