import React, { Component } from 'react'
import PropTypes from 'prop-types'

import style from './style.module.css'

export default class NavIcon extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }
    this.callOnClick = this.callOnClick.bind(this)
  }

  callOnClick() {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  defineClassName() {
    const { isOpen } = this.props
    if (isOpen) {
      return `${style.navIcon} ${style.open}`
    }
    return style.navIcon
  }

  render() {
    return (
      <button onClick={this.callOnClick} className={this.defineClassName()}>
        <span />
        <span />
        <span />
        <span />
      </button>
    )
  }
}

NavIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func
}

NavIcon.defaultProps = {
  onClick: undefined
}
