import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faCommentDots, faEnvelope } from '@fortawesome/fontawesome-free-solid'

import SideBar from '../../components/SideBar'

import { getEmptyChartData } from '../../core/helpers/ChartHelpers'
import { isFilledArray, isObjectWithProps } from '../../core/helpers/GenericHelper'

import * as socketService from '../../core/services/socketService'

import style from './style.module.css'

class RCMonitoring extends Component {
  state = {
    phoneTableData: {},
    chatTableData: {},
    emailTableData: {},
    whatsAppTableData: {},
    unifiedChannelsTableData: {}
  }

  socketRoom = 'rcMonitoring'

  phone = {
    name: 'Telefone',
    channelIds: [1],
    firstGroupLimit: 3,
    stateKey: 'phoneTableData',
    icon: faPhone,
    hasNSI: false
  }
  chat = {
    name: 'Chat',
    channelIds: [2],
    firstGroupLimit: 4,
    stateKey: 'chatTableData',
    icon: faCommentDots,
    hasNSI: true
  }
  email = {
    name: 'Email',
    channelIds: [3],
    firstGroupLimit: 4,
    stateKey: 'emailTableData',
    icon: faEnvelope,
    hasNSI: false
  }
  whatsApp = {
    name: 'WhatsApp',
    channelIds: [4],
    firstGroupLimit: 4,
    stateKey: 'whatsAppTableData',
    icon: faWhatsapp,
    hasNSI: true
  }
  unified = {
    name: 'Escritos',
    channelIds: [2, 4],
    firstGroupLimit: 4,
    stateKey: 'unifiedChannelsTableData',
    icon: faCommentDots,
    hasNSI: true
  }

  buildTableRows = (firstGroupLimit, hasNSI) => {
    const rows = [
      { rowName: 'Escalados', keyName: 'scheduled', formatType: 'text' },
      { rowName: 'Ativos', keyName: 'actives', formatType: 'text' },
      { rowName: 'Chamados Entrantes', keyName: 'realized', formatType: 'text' },
      { rowName: 'Planejado', keyName: 'planned', formatType: 'text' },
      { rowName: 'Produtividade', keyName: 'productivity', formatType: 'number' },
      { rowName: 'TMA', keyName: 'tma', formatType: 'time' },
      { rowName: 'Nível de Serviço', keyName: 'serviceLevel', formatType: 'percentage' },
      {
        rowName: `1 - ${firstGroupLimit} atendimentos`,
        keyName: 'group1',
        formatType: 'percentage'
      },
      {
        rowName: `${firstGroupLimit + 1} - 6 atendimentos`,
        keyName: 'group2',
        formatType: 'percentage'
      },
      { rowName: '7 - 9 atendimentos', keyName: 'group3', formatType: 'percentage' },
      { rowName: '+9 atendimentos', keyName: 'group4', formatType: 'percentage' }
    ]

    if (hasNSI) {
      rows.splice(6, 0, { rowName: 'NSI V2', keyName: 'nsi', formatType: 'percentage' })
    }

    return rows
  }

  componentDidMount() {
    const { selectedView } = this.props
    this._isMounted = true

    if (isObjectWithProps(selectedView)) {
      socketService.enterSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedView, socketInformationTrigger } = this.props
    const { enterSocketChannel, disconectSocketChannel } = socketService

    if (isObjectWithProps(selectedView)) {
      const { name: viewName } = selectedView
      const { selectedView: prevSelectedView } = prevProps

      if (!isObjectWithProps(prevSelectedView) || prevSelectedView.name !== viewName) {
        if (isObjectWithProps(prevSelectedView)) {
          disconectSocketChannel(this.socketRoom, [prevSelectedView.name])
        }
        enterSocketChannel(this.socketRoom, [viewName])
      }
    }

    if (prevProps.socketInformationTrigger !== socketInformationTrigger) {
      this.updateTablesData()
    }
  }

  componentWillUnmount() {
    const { selectedView } = this.props
    this._isMounted = false

    if (isObjectWithProps(selectedView)) {
      socketService.disconectSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  updateTablesData = () => {
    const { socketInformation } = socketService
    const { phone, chat, email, whatsApp, unified } = this

    if (isObjectWithProps(socketInformation) && socketInformation.halfHourlyMetrics) {
      this.updateTable(phone)
      this.updateTable(chat)
      this.updateTable(email)
      this.updateTable(whatsApp)
      this.updateTable(unified)
    }
  }

  updateTable = ({ stateKey, channelIds }) => {
    const { halfHourlyMetrics, forecasts, shiftsDetailed } = socketService.socketInformation

    const tableData = getEmptyChartData().map(item => {
      const hour = item.hour
      const minute = item.minute

      const hourChannelsMetrics =
        isObjectWithProps(halfHourlyMetrics) &&
        hour in halfHourlyMetrics &&
        isObjectWithProps(halfHourlyMetrics[hour][minute])
          ? halfHourlyMetrics[hour][minute].filter(({ channel_id }) =>
              channelIds.includes(channel_id)
            )
          : []

      const hourForecasts =
        isObjectWithProps(forecasts) && hour in forecasts && isFilledArray(forecasts[hour][minute])
          ? forecasts[hour][minute].filter(({ channel_id }) => channelIds.includes(channel_id))
          : []

      const hourActiveShifts = isFilledArray(shiftsDetailed)
        ? shiftsDetailed.filter(
            shift =>
              shift[`h_${hour}_${_.padStart(minute, 2, '0')}`] === 'A' &&
              channelIds.includes(shift.channelId)
          )
        : []

      const activeAgents = _.uniq(_.flatten(_.map(hourChannelsMetrics, 'active_agents'))).length
      const answeredInTime = _.sumBy(hourChannelsMetrics, 'answered_in_time')
      const totalReceived = _.sumBy(hourChannelsMetrics, 'total_received')
      const tertiaryDemands = _.sumBy(hourChannelsMetrics, 'tertiary_demands')
      const interactionBlocks = _.sumBy(hourChannelsMetrics, 'total_interaction_blocks')
      const interactedInTime = _.sumBy(hourChannelsMetrics, 'interacted_in_time') || 0
      const totalDuration = _.sumBy(hourChannelsMetrics, 'total_duration')

      const demandsToUse = stateKey !== 'emailTableData' ? totalReceived : tertiaryDemands

      const group1 = _.sumBy(hourChannelsMetrics, 'group_1')
      const group2 = _.sumBy(hourChannelsMetrics, 'group_2')
      const group3 = _.sumBy(hourChannelsMetrics, 'group_3')
      const group4 = _.sumBy(hourChannelsMetrics, 'group_4')

      item.scheduled = hourActiveShifts.length
      item.actives = activeAgents
      item.realized = demandsToUse
      item.planned = _.sumBy(hourForecasts, 'volume')

      if (activeAgents) {
        item.productivity = demandsToUse / activeAgents
        item.group1 = (group1 * 100) / activeAgents
        item.group2 = (group2 * 100) / activeAgents
        item.group3 = (group3 * 100) / activeAgents
        item.group4 = (group4 * 100) / activeAgents
      } else {
        item.productivity = '-'
        item.group1 = '-'
        item.group2 = '-'
        item.group3 = '-'
        item.group4 = '-'
      }

      if (totalReceived && stateKey !== 'emailTableData') {
        item.serviceLevel = (answeredInTime * 100) / totalReceived
        item.tma = totalDuration / totalReceived
      } else {
        item.serviceLevel = '-'
        item.tma = '-'
      }

      if (interactionBlocks) {
        item.nsi = Number((interactedInTime * 100) / interactionBlocks)
      } else {
        item.nsi = '-'
      }

      return item
    })

    this._isMounted && this.setState({ [stateKey]: tableData })
  }

  formatDataToRender = (data, formatType) => {
    if (!_.isNumber(data)) {
      return <span>&nbsp;</span>
    }

    let formattedData = data

    switch (formatType) {
      case 'percentage':
        formattedData = `${_.round(data, 0)}%`

        return formattedData
      case 'number':
        formattedData = _.round(data, 2)

        return formattedData
      case 'time':
        if (formattedData >= 60) {
          formattedData = `${_.round(formattedData / 60, 2)}m`
        } else {
          formattedData = `${_.round(formattedData, 2)}s`
        }

        return formattedData
      default:
        if (formattedData === 0) {
          formattedData = <span>&nbsp;</span>
        }
        return formattedData
    }
  }

  render = () => {
    const { phone, chat, email, whatsApp, unified, buildTableRows, formatDataToRender } = this

    return (
      <>
        <SideBar />

        <div id="noc-monitoring" style={{ marginTop: '15px' }}>
          {[phone, unified, chat, whatsApp, email].map(
            ({ name, firstGroupLimit, stateKey, icon, hasNSI }, index) =>
              isObjectWithProps(this.state[stateKey]) ? (
                <div className="card" style={{ margin: '15px', width: '100%' }} key={index}>
                  <div className={`card-header ${style.cardHeader}`}>
                    <FontAwesomeIcon icon={icon} /> {name}
                  </div>
                  <div style={{ width: '80%', overflowX: 'scroll', marginLeft: '12rem' }}>
                    <table className="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th scope="col" style={{ padding: 0 }} />
                          {this.state[stateKey].map(data => (
                            <th
                              className={`th ${style.tableDiv}`}
                              key={`${name} ${data.hour}:${data.minute}`}
                              scope="col"
                            >
                              {data.hour}:{_.padStart(data.minute, 2, '0')}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {buildTableRows(firstGroupLimit, hasNSI).map(
                          ({ rowName, keyName, formatType }, idx) => (
                            <tr key={idx}>
                              <th
                                scope="row"
                                style={{
                                  position: 'absolute',
                                  width: '12rem',
                                  left: 0,
                                  backgroundColor: idx % 2 ? '#FFFFFF' : '#F2F2F2',
                                  borderRight: '1px solid gray'
                                }}
                              >
                                {rowName}
                              </th>
                              {this.state[stateKey].map((data, secondIdx) => (
                                <td className={`td ${style.tableDiv}`} key={secondIdx}>
                                  {formatDataToRender(data[keyName], formatType)}
                                </td>
                              ))}
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                ''
              )
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ ...state.sideBarReducer, ...state.globalReducer })

export default connect(mapStateToProps)(withRouter(RCMonitoring))
