import React, { Component } from 'react'
import _ from 'lodash'

import WallboardCardData from '../../components/WallboardCard/WallboardCardData'
import WallboardCard from '../../components/WallboardCard'

import { isFilledArray } from '../../core/helpers/GenericHelper'

class EmailSummary extends Component {
  state = {
    metricsData: []
  }

  channelName = 'Email'
  channelId = 3

  componentDidMount() {
    this._isMounted = true

    this.refreshData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.casesInQueues !== this.props.casesInQueues) {
      this.refreshData()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  refreshData = () => {
    const metricsData = this.getMetricsData()

    this._isMounted && this.setState({ metricsData })
  }

  getMetricsData = () => {
    const { getDailyMetricsByChannel, getFormatMetricsFn } = this.props

    const emailCasesInQueues = this.handleCasesInQueues()

    const [emailDailyMetrics] = getDailyMetricsByChannel([this.channelName])

    const emailMetrics = { ...emailDailyMetrics, ...emailCasesInQueues }

    const cards = [
      {
        title: 'Casos na caixa',
        key: 'casesInQueues'
      },
      {
        title: 'Emails Recebidos Hoje',
        key: 'primarilyReceived'
      }
    ]

    return getFormatMetricsFn(emailMetrics, cards)
  }

  handleCasesInQueues = () => {
    const { casesInQueues } = this.props

    return { casesInQueues: isFilledArray(casesInQueues) ? _.sumBy(casesInQueues, 'ammount') : 0 }
  }

  render = () => {
    const { mode } = this.props
    const { metricsData } = this.state

    return (
      <WallboardCard title={this.channelName} width={33.33} mode={mode}>
        <WallboardCardData
          data={metricsData}
          channel={this.channelId}
          mode={mode}
          hasTable={false}
          isChannelEmail
        />
      </WallboardCard>
    )
  }
}

export default EmailSummary
