import React, { Component } from 'react'

import style from './style.module.css'

export default class SquadFilters extends Component {
  render = () => (
    <div>
      <div>
        <h3 className={style.search_options__title}>Opções de busca</h3>
        <p className={style.search_options__subtitle}>
          {'🔍 Filtre por Mesas ou Líder de Operação.'}
          <small>Digite o nome da Mesa ou Líder de Operação desejado.</small>
        </p>
      </div>

      <div className={style.search__container}>
        <div className={style.buttons__group}>
          <input
            type="text"
            placeholder="Pesquise por Mesa ou Líder de Operação..."
            className={style.search__input}
            onChange={this.props.handleSearch}
          />
        </div>
      </div>
    </div>
  )
}
