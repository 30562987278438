import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'

import HalfHourlyChart from '../../components/HalfHourlyChart'
import SideBar from '../../components/SideBar'
import ChartDataset from '../../core/models/ChartDataset'

import {
  defineYTicks,
  formatServiceLevelValue,
  getEmptyChartData,
  getShouldUpdateStr
} from '../../core/helpers/ChartHelpers'
import { isFilledArray, isObjectWithProps } from '../../core/helpers/GenericHelper'

import * as socketService from '../../core/services/socketService'

class RealXPlan extends Component {
  state = {
    phoneChartData: {},
    chatChartData: {},
    emailChartData: {},
    whatsappChartData: {},
    unifiedChannelsChartData: {}
  }

  socketRoom = 'realXPlan'
  chartName = 'minute-noc-with-service-level'

  chartHeight = '65vh'

  phone = 1
  chat = 2
  email = 3
  whatsApp = 4

  componentDidMount() {
    const { selectedView } = this.props
    this._isMounted = true

    if (isObjectWithProps(selectedView)) {
      socketService.enterSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedView, socketInformationTrigger } = this.props
    const { enterSocketChannel, disconectSocketChannel } = socketService

    if (isObjectWithProps(selectedView)) {
      const { name: viewName } = selectedView
      const { selectedView: prevSelectedView } = prevProps

      if (!isObjectWithProps(prevSelectedView) || prevSelectedView.name !== viewName) {
        if (isObjectWithProps(prevSelectedView)) {
          disconectSocketChannel(this.socketRoom, [prevSelectedView.name])
        }

        enterSocketChannel(this.socketRoom, [viewName])
      }
    }

    if (prevProps.socketInformationTrigger !== socketInformationTrigger) {
      this.updateRealXPlan()
    }
  }

  componentWillUnmount() {
    const { selectedView } = this.props
    this._isMounted = false

    if (isObjectWithProps(selectedView)) {
      socketService.disconectSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  updateRealXPlan = () => {
    const { socketInformation } = socketService
    const { phone, chat, email, whatsApp, chartName } = this

    if (isObjectWithProps(socketInformation)) {
      const { forecasts: planned, halfHourlyMetrics: real } = socketInformation

      if (planned && real) {
        this.updateChart(planned, real, 'phoneChartData', chartName, [phone], false)
        this.updateChart(planned, real, 'chatChartData', chartName, [chat])
        this.updateChart(planned, real, 'emailChartData', 'noc', [email], false)
        this.updateChart(planned, real, 'whatsappChartData', chartName, [whatsApp])
        this.updateChart(planned, real, 'unifiedChannelsChartData', chartName, [chat, whatsApp])
      }
    }
  }

  updateChart = (planned, real, stateKey, chartType, channelFilter, hasNSI = true) => {
    const chartData = getEmptyChartData().map(item => {
      const hour = item.hour
      const minute = item.minute

      const forecast =
        hour in planned && isFilledArray(planned[hour][minute])
          ? planned[hour][minute].filter(h => channelFilter.includes(h.channel_id))
          : []

      const halfHourlyMetric =
        hour in real && isFilledArray(real[hour][minute])
          ? real[hour][minute].filter(h => channelFilter.includes(h.channel_id))
          : []

      if (forecast.length) {
        item.planned = _.sumBy(forecast, 'volume')
      }

      if (isFilledArray(halfHourlyMetric)) {
        if (halfHourlyMetric.find(hm => hm.total_received - hm.tertiary_demands > 0)) {
          const answeredInTime = _.sumBy(halfHourlyMetric, 'answered_in_time')
          const totalReceived = _.sumBy(halfHourlyMetric, 'total_received')
          const tertiaryDemands = _.sumBy(halfHourlyMetric, 'tertiary_demands')

          const nonTertiaryDemands = totalReceived - tertiaryDemands

          item.realized = Number(nonTertiaryDemands)
          item.serviceLevel = formatServiceLevelValue(answeredInTime / nonTertiaryDemands)
        }

        if (hasNSI && halfHourlyMetric.find(hm => hm.total_interaction_blocks > 0)) {
          const interactionBlocks = _.sumBy(halfHourlyMetric, 'total_interaction_blocks')
          const interactedInTime = _.sumBy(halfHourlyMetric, 'interacted_in_time') || 0

          item.nsi = formatServiceLevelValue(interactedInTime / interactionBlocks)
        }
      }

      return item
    })

    this._isMounted &&
      this.setState({
        [stateKey]: new ChartDataset(chartData, chartType, hasNSI),
        [getShouldUpdateStr(stateKey)]: true
      })
  }

  render = () => {
    const {
      phoneChartData,
      chatChartData,
      emailChartData,
      whatsappChartData,
      unifiedChannelsChartData
    } = this.state

    const { chartHeight } = this

    return (
      <>
        <SideBar />

        <div id="noc-monitoring">
          <HalfHourlyChart
            title="Telefone"
            data={phoneChartData}
            ticks={defineYTicks(phoneChartData, 2)}
            dataStateName={'phoneChartData'}
            component={this}
            chartHeight={chartHeight}
          />
          <HalfHourlyChart
            title="Escritos"
            data={unifiedChannelsChartData}
            ticks={defineYTicks(unifiedChannelsChartData, 2)}
            dataStateName={'unifiedChannelsChartData'}
            component={this}
            chartHeight={chartHeight}
          />
          <HalfHourlyChart
            title="Whatsapp"
            data={whatsappChartData}
            ticks={defineYTicks(whatsappChartData, 2)}
            dataStateName={'whatsappChartData'}
            component={this}
            chartHeight={chartHeight}
          />
          <HalfHourlyChart
            title="Chat"
            data={chatChartData}
            ticks={defineYTicks(chatChartData, 2)}
            dataStateName={'chatChartData'}
            component={this}
            chartHeight={chartHeight}
          />
          <HalfHourlyChart
            title="Email"
            data={emailChartData}
            dataStateName={'emailChartData'}
            component={this}
            chartHeight={chartHeight}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ ...state.sideBarReducer, ...state.globalReducer })

export default connect(mapStateToProps)(withRouter(RealXPlan))
