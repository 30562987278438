import { init, setGroup, setUserId } from '@amplitude/analytics-browser'

import config from './appConfig'

class Amplitude {
  init() {
    init(config.amplitudeApiKey, {
      defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true
      }
    }).promise.then(() => setGroup('appName', 'rc-analytics-dashboards-twilio'))
  }

  setProfile(profileName) {
    setGroup('profile', profileName)
  }

  setSelectedView(selectedView) {
    setGroup('selectedView', selectedView)
  }

  setUserId(userEmail) {
    setUserId(userEmail)
  }
}

export default new Amplitude()
