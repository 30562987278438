import React, { Component } from 'react'

import style from './style.module.css'

export default class ChannelFilters extends Component {
  name = 'channel'

  options = [
    { label: 'Todos os canais', value: '', checked: true },
    { label: 'Telefone', value: 'voice' },
    { label: 'E-mail', value: 'email' },
    { label: 'Escritos', value: 'escritos' }
  ]

  onChange = event => {
    this.setState({ checkedValue: event.target.value })
    this.props.handleFilter(event)
  }

  render = () => {
    return (
      <div>
        <div>
          <h3 className={style.search_options__title}>Opções de filtro</h3>
          <p className={style.search_options__subtitle}>
            🔍 Filtre pelos canais de atendimento
            <small>Selecione o canal desejado.</small>
          </p>
        </div>

        <div className={style.search__container} onChange={this.onChange}>
          {this.options.map(({ label, value, checked }) => (
            <div key={label}>
              <input
                id={label}
                type="radio"
                name="channel"
                value={value}
                defaultChecked={Boolean(checked)}
              />
              <label htmlFor={label}>{label}</label>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
