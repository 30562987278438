import React, { Component } from 'react'
import _ from 'lodash'

import WallboardCard from '../../components/WallboardCard'

import { isFilledArray, isObjectWithProps } from '../../core/helpers/GenericHelper'
import { getTcxMetricsForView } from '../../core/helpers/ChannelSummaryHelper'

import style from './style.module.css'

class DepartmentSummary extends Component {
  state = {
    phone3cxData: { channel: 'Fone 3cx' },
    phoneTwilioData: { channel: 'Fone Twilio' },
    chatData: { channel: 'Chat' },
    whatsAppData: { channel: 'WhatsApp' },
    unifiedData: { channel: 'Escritos' }
  }

  channels = [
    { ids: [1], tool: '3cx', name: 'Telefone 3cx', stateKey: 'phone3cxData' },
    { ids: [1], tool: 'Twilio', name: 'Telefone Twilio', stateKey: 'phoneTwilioData' },
    { ids: [2], tool: 'Twilio', name: 'Chat', stateKey: 'chatData' },
    { ids: [4], tool: 'Twilio', name: 'WhatsApp', stateKey: 'whatsAppData' },
    { ids: [2, 4], tool: 'Twilio', name: 'Escritos', stateKey: 'unifiedData' }
  ]

  tableCols = [
    { colName: 'Canal', keyName: 'channel', defaultValue: '-', formatType: 'text' },
    { colName: 'Fila', keyName: 'queueSize', defaultValue: 0, formatType: 'text' },
    { colName: 'Atendimentos agora', keyName: 'activeSize', defaultValue: 0, formatType: 'text' },
    { colName: 'Logados', keyName: 'loggedIn', defaultValue: 0, formatType: 'text' },
    { colName: 'NS', keyName: 'serviceLevel', defaultValue: '-', formatType: 'percentage' },
    { colName: 'NSI V2', keyName: 'nsi', defaultValue: '-', formatType: 'percentage' },
    { colName: 'TME', keyName: 'tme', defaultValue: '-', formatType: 'time' },
    { colName: 'TMI V2', keyName: 'tmi', defaultValue: '-', formatType: 'time' },
    { colName: 'TMA', keyName: 'tma', defaultValue: '-', formatType: 'time' }
  ]

  componentDidUpdate(prevProps) {
    if (this.props.view && prevProps.followupState !== this.props.followupState) {
      this.getCurrentViewData()
    }
  }

  separateTwilioData = () => {
    const { view, followupState } = this.props
    const { twilioIncomingActive, twilioMetrics } = followupState
    const { channels } = this

    const dataByChannel = {
      1: { loggedIds: [], queueSize: 0, activeSize: 0 },
      2: { loggedIds: [], queueSize: 0, activeSize: 0 },
      4: { loggedIds: [], queueSize: 0, activeSize: 0 }
    }

    if (isObjectWithProps(twilioIncomingActive)) {
      for (const { id, channel_id } of view.queues) {
        if (id in twilioIncomingActive) {
          for (const { metric, value } of twilioIncomingActive[id]) {
            if (metric === 'active_chats') {
              dataByChannel[channel_id].activeSize += value
            } else if (metric === 'incoming_chats') {
              dataByChannel[channel_id].queueSize += value
            }
          }
        }
      }
    }

    if (isObjectWithProps(twilioMetrics)) {
      for (const { id, channel_id } of view.queues) {
        if (id in twilioMetrics) {
          for (const { metric, sids } of twilioMetrics[id]) {
            if (metric === 'agents_available_in_queue') {
              dataByChannel[channel_id].loggedIds.push(...sids)
            }
          }
        }
      }
    }

    const separatedData = channels
      .filter(({ tool }) => tool === 'Twilio')
      .map(({ ids }) => {
        const loggedIds = []
        let queueSize = 0
        let activeSize = 0

        for (const id of ids) {
          loggedIds.push(...dataByChannel[id].loggedIds)

          queueSize += dataByChannel[id].queueSize
          activeSize += dataByChannel[id].activeSize
        }

        const uniqLoggedIds = _.uniq(loggedIds)

        return {
          loggedIn: uniqLoggedIds.length,
          queueSize,
          activeSize,
          loggedIds: uniqLoggedIds
        }
      })

    return separatedData
  }

  separateDailyMetricsData = () => {
    const { followupState, title } = this.props
    const { dailyMetrics: completeDailyMetrics } = followupState
    const { channels } = this

    const dailyMetrics = isFilledArray(completeDailyMetrics)
      ? completeDailyMetrics.filter(({ view_name }) => view_name === title)
      : []

    const separatedData = channels.map(({ ids, tool: channelTool }) => {
      const currentChannelsQueues = dailyMetrics.filter(
        ({ channel_id, tool, total_received }) =>
          ids.includes(channel_id) && channelTool === tool && total_received > 0
      )

      const answeredInTime = _.sumBy(currentChannelsQueues, 'answered_in_time')
      const totalWaitingTime = _.sumBy(currentChannelsQueues, 'total_waiting_time')
      const totalInteractionTime = _.sumBy(currentChannelsQueues, 'total_interaction_time')
      const totalDuration = _.sumBy(currentChannelsQueues, 'total_duration')
      const totalReceived = _.sumBy(currentChannelsQueues, 'total_received')
      const totalInteractionBlocks = _.sumBy(currentChannelsQueues, 'total_interaction_blocks')
      const interactedInTime = _.sumBy(currentChannelsQueues, 'interacted_in_time') || 0

      let serviceLevel = '-'
      let tme = '-'
      let tmi = '-'
      let tma = '-'
      let nsi = '-'

      if (totalReceived > 0) {
        serviceLevel = (answeredInTime * 100) / totalReceived
        tme = totalWaitingTime / totalReceived
        tma = totalDuration / totalReceived
      }

      if (totalInteractionBlocks > 0) {
        tmi = totalInteractionTime / totalInteractionBlocks
        nsi = (interactedInTime * 100) / totalInteractionBlocks
      }

      return { serviceLevel, tme, tmi, tma, nsi }
    })

    return separatedData
  }

  getCurrentViewData = () => {
    const { view, followupState } = this.props
    const { workforceMembers, activeCalls, loggedInExtensions } = followupState

    const tcxPhoneData = getTcxMetricsForView(
      view,
      workforceMembers,
      activeCalls,
      loggedInExtensions
    )

    const [
      twilioPhoneData,
      twilioChatData,
      twilioWhatsAppData,
      twilioUnifiedData
    ] = this.separateTwilioData()

    twilioUnifiedData['loggedIn'] = _.intersection(
      twilioChatData.loggedIds,
      twilioWhatsAppData.loggedIds
    ).length

    const [
      phone3cxDailyMetrics,
      phoneTwilioDailyMetrics,
      chatDailyMetrics,
      whatsAppDailyMetrics,
      unifiedDailyMetrics
    ] = this.separateDailyMetricsData()

    const phone3cxData = { channel: 'Fone 3cx', ...tcxPhoneData, ...phone3cxDailyMetrics }

    const phoneTwilioData = {
      channel: 'Fone Twilio',
      ...twilioPhoneData,
      ...phoneTwilioDailyMetrics
    }

    const chatData = { channel: 'Chat', ...twilioChatData, ...chatDailyMetrics }

    const whatsAppData = { channel: 'WhatsApp', ...twilioWhatsAppData, ...whatsAppDailyMetrics }

    const unifiedData = { channel: 'Escritos', ...twilioUnifiedData, ...unifiedDailyMetrics }

    this.setState({ phone3cxData, phoneTwilioData, chatData, whatsAppData, unifiedData })
  }

  formatDataToRender = (data, formatType) => {
    let formattedData = data

    switch (formatType) {
      case 'time':
        if (_.isNumber(formattedData)) {
          if (formattedData >= 60) {
            formattedData = `${_.round(formattedData / 60, 2)}m`
          } else {
            formattedData = `${_.round(formattedData, 2)}s`
          }
        }

        return formattedData
      case 'percentage':
        if (_.isNumber(formattedData)) {
          formattedData = `${_.round(data, 2)}%`
        }

        return formattedData
      default:
        return formattedData
    }
  }

  render = () => {
    const { channels, tableCols, formatDataToRender } = this

    return (
      <WallboardCard title={this.props.title} width={50}>
        <table className={style.wallboardTableHeader}>
          <thead>
            <tr>
              {tableCols.map(({ colName }, index) => (
                <th className={style.wallboardTableHeaderColumn} key={index}>
                  {colName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {channels.map(({ stateKey }, rowIndex) => (
              <tr className={style.wallboardTableRow} key={rowIndex + 'tr'}>
                {tableCols.map(({ keyName, defaultValue, formatType }, colIndex) => (
                  <td className={style.wallboardTableColumn} key={colIndex}>
                    {this.state[stateKey][keyName] || this.state[stateKey][keyName] === 0
                      ? formatDataToRender(this.state[stateKey][keyName], formatType)
                      : defaultValue}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </WallboardCard>
    )
  }
}

export default DepartmentSummary
