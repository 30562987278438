import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'

import Clock from '../Clock'
import NavIcon from '../NavIcon'
import Dropdown from '../../components/Dropdown'
import WallboardServices from '../../core/services/WallboardServices'
import amplitude from '../../core/services/amplitude'

import * as sideBarActions from '../../redux/actions/sideBarActions'
import * as globalActions from '../../redux/actions/globalActions'
import * as AuthHelper from '../../core/helpers/AuthHelpers'

import * as socketService from '../../../src/core/services/socketService'
import style from './style.module.css'

class SideBar extends Component {
  state = {
    isOpen: false,
    views: [],
    options: []
  }

  componentDidMount() {
    this._isMounted = true

    this.setViewsInterval(undefined, socketService.connectOnSocket)
  }

  componentDidUpdate(prevProps) {
    this.setViewsInterval(prevProps)

    if (this.wasIsolatedPropSet() && !this.state.options.length) {
      this.createRouteOptions()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    clearInterval(this.interval)
  }

  hasToken = () => {
    const token = AuthHelper.getToken(false)
    return token && token !== 'null'
  }

  wasIsolatedPropSet = () => this.props.isIsolatedView !== undefined

  getViews = viewName => {
    WallboardServices.getWallboardViewsComplete({ view_name: viewName })
      .then(({ data }) => {
        const { data: viewsComplete } = data

        const desiredViews = viewsComplete.by_view || viewsComplete

        this.props.sideBarActions.saveViews(desiredViews)

        const newViewsState = Object.entries(desiredViews).map(([key, values]) => ({
          key,
          name: key,
          value: key,
          values
        }))

        this.setState({ views: newViewsState })
      })
      .catch(e => AuthHelper.handleUnauthorizedResponse(this.props.history, e))
  }

  createRouteOptions = () => {
    const options = [
      {
        path: '/general-wallboard',
        title: 'Wallboard Geral'
      },
      {
        path: '/csi',
        title: 'CSI Geral'
      },
      {
        path: '/twilio-agents',
        title: 'Twilio Agentes'
      },
      {
        path: '/rc-monitoring',
        title: 'Monitoramento RC'
      },
      {
        path: '/real-x-plan',
        title: 'Real x Plan'
      },
      {
        path: '/tmi',
        title: 'TMI V2'
      },
      {
        path: '/tma',
        title: 'TMA'
      },
      {
        path: '/noc-subjects',
        title: 'NOC Assuntos'
      },
      {
        path: '/noc-banks',
        title: 'NOC Bancos'
      },
      {
        path: '/noc-incidents',
        title: 'NOC Incidentes'
      }
    ]

    if (!this.props.isIsolatedView) {
      options.splice(1, 0, { path: '/operation-followup', title: 'Acompanhamento Operações' })
      options.splice(11, 0, { path: '/shifts', title: 'Escala Líquida' })
    }

    this.setState({ options })
  }

  logoutSessions = () => {
    const { globalActions, history } = this.props

    globalActions.toggleLoader(true)

    AuthHelper.clearAllStorage()

    history.push('/')

    window.location.reload()
  }

  toggleSideBar = () => this.setState({ isOpen: !this.state.isOpen })

  handleChange = value => {
    const { views, sideBarActions } = this.props

    const newView = { key: value, name: value, value, values: views[value] }

    window.localStorage.setItem('selectedView', JSON.stringify(newView))
    amplitude.setSelectedView(value)
    sideBarActions.updateSelectedView(newView)

    this.toggleSideBar()
  }

  getViewName = () => {
    const { selectedView } = this.props

    return selectedView && selectedView.name
  }

  setViewsInterval = (prevProps = undefined, cbFn = () => {}) => {
    const { history, isIsolatedView, sideBarActions } = this.props
    const { socket } = socketService

    const forbiddenPaths = ['/', '/healthz']

    const rawView = localStorage.getItem('selectedView')

    if (!forbiddenPaths.includes(history.location.pathname) && this.hasToken()) {
      const updateConditional = !prevProps || prevProps.isIsolatedView !== isIsolatedView

      if (this.wasIsolatedPropSet() && updateConditional && rawView) {
        let viewName = undefined

        const parsedView = JSON.parse(rawView)
        sideBarActions.updateSelectedView(parsedView)

        if (isIsolatedView) {
          viewName = parsedView.name
        } else {
          this.getViews()
        }

        this.interval = setInterval(() => this.getViews(viewName), 2 * 60 * 1000)
      }

      if (!socket || !socket.id) {
        cbFn()
      }
    }
  }

  render() {
    const { selectedView, isIsolatedView } = this.props
    const { views, isOpen, options } = this.state

    return (
      <div>
        <div id="side-bar" className={`${style.sidebar} ${isOpen ? '' : style.closed}`}>
          <div className={style.sidebar__content}>
            {!isIsolatedView ? (
              <Dropdown
                label={'Selecione o Departamento'}
                name={'Selecione o Departamento'}
                options={views}
                onChange={this.handleChange}
                value={selectedView && selectedView.name}
                invisible={true}
              />
            ) : null}
            <ul className={style['side-bar__ul']}>
              {options.map(option => (
                <li key={option.title}>
                  <Link onClick={this.toggleSideBar} to={option.path}>
                    {option.title}
                  </Link>
                </li>
              ))}
              <li className={style['side-bar__li']}>
                <button onClick={this.logoutSessions} className={style['side-bar__logout']}>
                  Sair
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className={style['side-bar__header']}>{this.getViewName()}</div>
        <Clock />
        <NavIcon onClick={this.toggleSideBar} isOpen={isOpen} />
      </div>
    )
  }
}

SideBar.propTypes = {
  sideBarActions: PropTypes.shape({
    saveViews: PropTypes.func,
    updateSelectedView: PropTypes.func
  }).isRequired,
  globalActions: PropTypes.shape({
    toggleLoader: PropTypes.func
  }).isRequired,
  selectedView: PropTypes.object,
  isIsolatedView: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
    listen: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  }).isRequired
}

function mapStateToProps(state) {
  return { ...state.sideBarReducer }
}

function mapDispatchToProps(dispatch) {
  return {
    sideBarActions: bindActionCreators(sideBarActions, dispatch),
    globalActions: bindActionCreators(globalActions, dispatch)
  }
}

export { SideBar as SideBarDefault }

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SideBar))
