import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'

import HalfHourlyChart from '../../components/HalfHourlyChart'
import SideBar from '../../components/SideBar'
import ChartDataset from '../../core/models/ChartDataset'

import {
  defineYTicks,
  formatServiceLevelValue,
  getEmptyChartData,
  getShouldUpdateStr
} from '../../core/helpers/ChartHelpers'
import { isFilledArray, isObjectWithProps } from '../../core/helpers/GenericHelper'

import * as socketService from '../../core/services/socketService'

class TMI extends Component {
  state = {
    chatChartData: {},
    whatsappChartData: {},
    unifiedChannelsChartData: {}
  }

  socketRoom = 'tmi'

  chat = 2
  whatsApp = 4

  componentDidMount() {
    const { enterSocketChannel } = socketService
    const { selectedView } = this.props
    this._isMounted = true

    if (isObjectWithProps(selectedView)) {
      enterSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedView, socketInformationTrigger } = this.props
    const { enterSocketChannel, disconectSocketChannel } = socketService

    if (isObjectWithProps(selectedView)) {
      const { name: viewName } = selectedView
      const { selectedView: prevSelectedView } = prevProps

      if (!isObjectWithProps(prevSelectedView) || prevSelectedView.name !== viewName) {
        if (isObjectWithProps(prevSelectedView)) {
          disconectSocketChannel(this.socketRoom, [prevSelectedView.name])
        }

        enterSocketChannel(this.socketRoom, [viewName])
      }
    }

    if (prevProps.socketInformationTrigger !== socketInformationTrigger) {
      this.updateTMIChart()
    }
  }

  componentWillUnmount() {
    const { disconectSocketChannel } = socketService
    const { selectedView } = this.props
    this._isMounted = false

    if (isObjectWithProps(selectedView)) {
      disconectSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  updateTMIChart = () => {
    const { socketInformation } = socketService
    const { chat, whatsApp } = this

    if (isObjectWithProps(socketInformation)) {
      const { halfHourlyMetrics: data } = socketInformation

      if (data) {
        this.updateChart(data, 'chatChartData', [chat])
        this.updateChart(data, 'whatsappChartData', [whatsApp])
        this.updateChart(data, 'unifiedChannelsChartData', [chat, whatsApp])
      }
    }
  }

  updateChart = (data, stateKey, channelFilter) => {
    const chartData = getEmptyChartData().map(item => {
      const hour = item.hour
      const minute = item.minute

      const halfHourlyMetrics =
        hour in data && isFilledArray(data[hour][minute])
          ? data[hour][minute].filter(h => channelFilter.includes(h.channel_id))
          : []

      if (isFilledArray(halfHourlyMetrics)) {
        if (halfHourlyMetrics.find(hm => hm.total_received > 0)) {
          const answeredInTime = _.sumBy(halfHourlyMetrics, 'answered_in_time')
          const totalReceived = _.sumBy(halfHourlyMetrics, 'total_received')

          item.serviceLevel = formatServiceLevelValue(answeredInTime / totalReceived)
        }

        if (halfHourlyMetrics.find(hm => hm.total_interaction_blocks > 0)) {
          const interactionTime = _.sumBy(halfHourlyMetrics, 'total_interaction_time')
          const interactionBlocks = _.sumBy(halfHourlyMetrics, 'total_interaction_blocks')
          const interactedInTime = _.sumBy(halfHourlyMetrics, 'interacted_in_time') || 0

          item.realized = Number(interactionTime / interactionBlocks).toFixed(2)
          item.nsi = formatServiceLevelValue(interactedInTime / interactionBlocks)
        }
      }

      return item
    })

    this._isMounted &&
      this.setState({
        [stateKey]: new ChartDataset(chartData, 'tmi-with-service-level'),
        [getShouldUpdateStr(stateKey)]: true
      })
  }

  render = () => {
    const { chatChartData, whatsappChartData, unifiedChannelsChartData } = this.state

    return (
      <>
        <SideBar />
        <div id="noc-monitoring">
          <HalfHourlyChart
            title="Escritos"
            data={unifiedChannelsChartData}
            ticks={defineYTicks(unifiedChannelsChartData, 1)}
            dataStateName={'unifiedChannelsChartData'}
            component={this}
          />
          <HalfHourlyChart
            title="Chat"
            data={chatChartData}
            ticks={defineYTicks(chatChartData, 1)}
            dataStateName={'chatChartData'}
            component={this}
          />
          <HalfHourlyChart
            title="Whatsapp"
            data={whatsappChartData}
            ticks={defineYTicks(whatsappChartData, 1)}
            dataStateName={'whatsappChartData'}
            component={this}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ ...state.sideBarReducer, ...state.globalReducer })

export default connect(mapStateToProps)(withRouter(TMI))
