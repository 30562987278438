import React, { Component } from 'react'
import moment from 'moment'
import style from './style.module.css'

class Clock extends Component {
  constructor(props) {
    super(props)

    this.state = {
      time: moment().format('HH:mm:ss')
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  tick() {
    this.setState({
      time: moment().format('HH:mm:ss')
    })
  }

  render() {
    return <div className={style.clock}>{this.state.time}</div>
  }
}

export default Clock
