import React, { Component } from 'react'
import style from './style.module.css'

import WallboardServices from '../../core/services/WallboardServices'

class LoginAzure extends Component {
  componentDidUpdate(prevProps) {
    const { triggerLogin } = this.props

    if (triggerLogin && !prevProps.triggerLogin) {
      this.loginAzure()
    }
  }

  loginAzure = () => {
    this.props.setLoading(true)

    WallboardServices.getOauthAzureConfigurations()
      .then(({ data }) => {
        const { url, tenant, clientId, scope } = data

        const params = new URLSearchParams({
          client_id: clientId,
          redirect_uri: `${window.location.origin}/`,
          response_type: 'code',
          scope: scope
        })

        window.open(`${url}/${tenant}/oauth2/v2.0/authorize?${params.toString()}`, '_self')
      })
      .catch(err => {
        console.error(err)
        this.props.setLoading(false)
        window.location.reload()
      })
  }

  render = () => (
    <div>
      <input
        type="submit"
        className={style.login__button}
        onClick={this.loginAzure}
        disabled={this.props.isLoading}
        value="Login SSO"
      />
    </div>
  )
}

export default LoginAzure
