import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { isEmpty, max } from 'lodash'

import Chart from '../../components/Chart'
import Table from '../../components/Table'

import AllBanksTable from '../../core/models/AllBanksTable'
import ChartDataset from '../../core/models/ChartDataset'

import * as socketService from '../../core/services/socketService'

import style from './style.module.css'
import SideBar from '../../components/SideBar'

class NocBanks extends Component {
  state = {
    topChartData: {},
    allBanksTableData: [],
    shouldUpdateInfo: false
  }

  componentDidMount() {
    const { enterSocketChannel } = socketService
    const { selectedView } = this.props
    this._isMounted = true

    if (selectedView) {
      enterSocketChannel('nocBanks', [selectedView.name])
    }
  }

  componentDidUpdate(prevProps) {
    const { disconectSocketChannel, enterSocketChannel } = socketService
    const { socketInformationTrigger, selectedView } = this.props

    if (
      prevProps.selectedView &&
      selectedView &&
      prevProps.selectedView.name !== selectedView.name
    ) {
      disconectSocketChannel('nocBanks', [prevProps.selectedView.name])
      enterSocketChannel('nocBanks', [selectedView.name])
    }

    if (prevProps.socketInformationTrigger !== socketInformationTrigger) {
      this.updateDashboard()
    }
  }

  componentWillUnmount() {
    const { disconectSocketChannel } = socketService
    const { selectedView } = this.props
    this._isMounted = false

    if (selectedView) {
      disconectSocketChannel('nocBanks', [selectedView.name])
    }
  }

  updateDashboard = () => {
    const { socketInformation } = socketService

    if (socketInformation && socketInformation.nocBanks) {
      const allBanksTableData = socketInformation.nocBanks.map(
        subject => new AllBanksTable(subject)
      )
      const topChartData = new ChartDataset(socketInformation.nocBanks, 'noc-banks')

      this._isMounted && this.setState({ topChartData, allBanksTableData, shouldUpdateInfo: true })
    }
  }

  defineLineTicks = () => {
    const { topChartData } = this.state

    if (!isEmpty(topChartData)) {
      const maxValue = max(topChartData.datasets[4].data) * 1.05

      return {
        max: maxValue,
        min: 0,
        stepSize: maxValue / 10
      }
    }
    return {
      max: 1.2,
      min: 0,
      stepSize: 0.1
    }
  }

  render = () => {
    const { topChartData, allBanksTableData, shouldUpdateInfo } = this.state

    return (
      <>
        <SideBar />

        <div id="noc-banks">
          <div className={style['noc-banks-chart--table']}>
            <Chart
              id="nocBanks"
              data={topChartData}
              type="bar"
              title="Chamados de Pagamento por Banco"
              height="75vh"
              width="75%"
              extraYScales={{
                ticks: this.defineLineTicks(),
                spanGaps: false,
                gridLines: {
                  display: false
                },
                id: 'noc-banks-percent',
                type: 'linear',
                position: 'right'
              }}
              shouldUpdateInfo={shouldUpdateInfo}
              handShakeFn={() => this.setState({ shouldUpdateInfo: false })}
            />
          </div>
          <Table
            data={allBanksTableData}
            pageSize={10}
            tableTitle="Todos os Bancos"
            tableClassName="table-full"
            defaultSortedId="d0"
            headers={[
              {
                headerText: 'Banco',
                accessor: 'bank',
                width: 500
              },
              {
                headerText: 'D-21',
                accessor: 'd21'
              },
              {
                headerText: 'D-14',
                accessor: 'd14'
              },
              {
                headerText: 'D-7',
                accessor: 'd7'
              },
              {
                headerText: 'D-0',
                accessor: 'd0'
              },
              {
                headerText: 'Média',
                accessor: 'weeklyAverage'
              },
              {
                headerText: 'Desvio',
                accessor: 'deviation'
              },
              {
                headerText: 'Preocupante',
                accessor: 'isWorrying'
              }
            ]}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ ...state.sideBarReducer, ...state.globalReducer })

export default connect(mapStateToProps)(withRouter(NocBanks))
