export function toggleIsolatedView(isIsolatedView) {
  return dispatch => {
    dispatch({ type: 'TOGGLE_ISOLATED_VIEW', isIsolatedView })
  }
}

export function saveViews(views) {
  return dispatch => {
    dispatch({ type: 'SAVE_VIEWS', views })
  }
}

export function updateSelectedView(selectedView) {
  return dispatch => {
    dispatch({ type: 'UPDATE_SELECTED_VIEW', selectedView })
  }
}
