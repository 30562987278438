import React from 'react'
import PropTypes from 'prop-types'

import style from './style.module.css'

const WallboardCardDataItem = props => {
  // TODO: set up the threshold variables and make a logic that makes sense
  const defineDataColor = threshhold => {
    const { info } = props.data
    if (info < threshhold) {
      return style.green
    }
    return style.white
  }

  const defineCardContentClass = () => {
    if (props.bigHeightItems) {
      return style.cardContentItemVerticalBig
    }
    return style[
      `cardContentItem${props.color}${props.isVertical && !props.color ? 'Vertical' : ''}`
    ]
  }

  const defineHeight = () => {
    const { unifiedCard, channel, smallText, isChannelEmail } = props

    if (unifiedCard) {
      if (channel === 1) return '24vh'
      return '40vh'
    }
    if (smallText) return '12vh'
    if (isChannelEmail) return '21vh'
    return '20vh'
  }

  return (
    <div
      className={defineCardContentClass()}
      style={{
        width: `${props.isVertical ? '100%' : `${props.width}%`}`,
        height: `${defineHeight()}`
      }}
    >
      {props.withDivider && !props.isChannelEmail ? (
        <div className={style.cardContentWithDividerContainer}>
          <div className={style.cardContentTitleWithDivider}>{props.data.title}</div>
          <div className={`${style.cardContentDataMin} ${defineDataColor(999999999)}`}>
            {props.data.info}
          </div>

          <div className={style.cardContentTitleWithDivider}>{props.withDivider.title}</div>
          <div className={`${style.cardContentDataMin} ${defineDataColor(999999999)}`}>
            {props.withDivider.info}
          </div>
        </div>
      ) : (
        [
          <div className={style.cardContentTitle} key="propsDataTitle">
            {props.data.title}
          </div>,
          <div
            className={`${
              props.smallText ? style.cardContentDataMin : style.cardContentData
            } ${defineDataColor(999999999)}`}
            key="propsDataInfo"
          >
            {props.data.info}
          </div>
        ]
      )}
    </div>
  )
}

WallboardCardDataItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  width: PropTypes.number,
  isVertical: PropTypes.bool,
  color: PropTypes.string,
  bigHeightItems: PropTypes.bool,
  withDivider: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  isChannelEmail: PropTypes.bool,
  unifiedCard: PropTypes.bool
}

WallboardCardDataItem.defaultProps = {
  width: 20,
  isVertical: false,
  color: '',
  bigHeightItems: false,
  withDivider: false,
  isChannelEmail: false,
  unifiedCard: false
}

export default WallboardCardDataItem
