import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'

import DepartmentSummary from '../../components/DepartmentSummary'
import SideBar from '../../components/SideBar'

import { isObjectWithProps } from '../../core/helpers/GenericHelper'

import * as socketService from '../../core/services/socketService'

import style from './style.module.css'

class OperationFollowup extends Component {
  state = {
    dailyMetrics: undefined,
    activeCalls: undefined,
    twilioIncomingActive: undefined,
    twilioMetrics: undefined,
    loggedInExtensions: undefined,
    workforceMembers: undefined
  }

  socketRoom = 'operationFollowup'
  viewsGroups = [
    ['Front Stone'],
    ['Safety', 'Reconquista', 'Vendas - Crédito', 'Vendas - Seguro'],
    ['Retenção', 'N2 - Risco'],
    ['GPS (RT)', 'Fran', 'Liga - Fran', 'GPS - Linha Verde'],
    ['SAP', 'N2 - Banking', 'Seguros N2', 'N2 - Help Fin', 'N2 - Help Tech'],
    ['Especialistas TON', 'AeC', 'Concentrix', 'Teleperformance', 'Teleperformance Especialistas']
  ]

  incrementViewsGroups() {
    const completeViews = this.props.views

    if (isObjectWithProps(completeViews)) {
      const dynamicGroup = []

      const currentGroupSet = new Set(this.viewsGroups.flat())

      Object.keys(completeViews).forEach(teamValue => {
        if (!currentGroupSet.has(teamValue)) {
          dynamicGroup.push(teamValue)
        }
      })

      if (dynamicGroup.length) {
        this.viewsGroups.push(dynamicGroup)
      }
    }
  }

  componentDidMount() {
    this.incrementViewsGroups()

    this._isMounted = true

    socketService.enterSocketChannel(this.socketRoom, _.flatten(this.viewsGroups))
  }

  componentDidUpdate(prevProps) {
    if (prevProps.views !== this.props.views) {
      socketService.disconectSocketChannel(this.socketRoom, _.flatten(this.viewsGroups))

      this.incrementViewsGroups()

      socketService.enterSocketChannel(this.socketRoom, _.flatten(this.viewsGroups))
    }

    if (prevProps.socketInformationTrigger !== this.props.socketInformationTrigger) {
      this.updateData()
    }
  }

  componentWillUnmount() {
    this._isMounted = false

    socketService.disconectSocketChannel(this.socketRoom, _.flatten(this.viewsGroups))
  }

  updateData = _.throttle(() => {
    const { socketInformation } = socketService

    if (isObjectWithProps(socketInformation)) {
      const {
        dailyMetrics,
        activeCalls,
        twilioIncomingActive,
        twilioMetrics,
        loggedInExtensions,
        workforceMembers
      } = socketInformation

      this._isMounted &&
        this.setState({
          dailyMetrics,
          activeCalls,
          twilioIncomingActive,
          twilioMetrics,
          loggedInExtensions,
          workforceMembers
        })
    }
  }, 2000)

  render = () => {
    const { viewsGroups } = this
    const { views } = this.props

    return (
      <>
        <SideBar />

        <div className={style.container}>
          {views
            ? viewsGroups.map((currentGroup, currentIndex) => (
                <div key={currentIndex}>
                  <div className={style.profileGroupContainer}>
                    {currentGroup.map(currentView => (
                      <DepartmentSummary
                        key={currentView}
                        title={currentView}
                        view={views[currentView]}
                        followupState={this.state}
                      />
                    ))}
                  </div>
                  {currentIndex !== viewsGroups.length - 1 && (
                    <hr className={style.profileGroupDivider} />
                  )}
                </div>
              ))
            : null}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ ...state.sideBarReducer, ...state.globalReducer })

export default connect(mapStateToProps)(withRouter(OperationFollowup))
