import React, { Component } from 'react'
import _ from 'lodash'

import WallboardCardData from '../../components/WallboardCard/WallboardCardData'
import WallboardCard from '../../components/WallboardCard'

import { isObjectWithProps, isFilledArray } from '../../core/helpers/GenericHelper'
import { formatTimeData, getTcxMetricsForView } from '../../core/helpers/ChannelSummaryHelper'

class PhoneSummary extends Component {
  state = {
    tcxMetrics: [],
    twilioMetrics: [],
    unifiedMetrics: [],
    shiftsData: [],
    showUnifiedMetrics: true
  }

  unifiedChannelName = 'Telefone Unidos'
  channelNames = ['Telefone 3cx', 'Telefone Twilio']
  channelId = 1

  componentDidMount() {
    this._isMounted = true

    this.refreshData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.refreshData()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  refreshData = () => {
    const [tcxMetrics, twilioMetrics, unifiedMetrics] = this.getMetricsData()
    const shiftsData = this.getShiftsData()

    this._isMounted && this.setState({ tcxMetrics, twilioMetrics, unifiedMetrics, shiftsData })
  }

  getMetricsData = () => {
    const {
      view,
      workforceMembers,
      activeCalls,
      getTwilioMetricsForViewByChannel,
      getDailyMetricsByChannel,
      getFormatMetricsFn,
      commonMetrics
    } = this.props

    const { values: selectedView } = view

    const phoneTcxMetrics = getTcxMetricsForView(selectedView, workforceMembers, activeCalls)
    const phoneTwilioMetrics = getTwilioMetricsForViewByChannel(this.channelId)
    const [
      tcxDailyMetrics,
      twilioDailyMetrics,
      unifiedPhoneDailyMetrics
    ] = getDailyMetricsByChannel([...this.channelNames, this.unifiedChannelName])

    const rawTcxMetrics = { ...phoneTcxMetrics, ...tcxDailyMetrics }
    const rawTwilioMetrics = { ...phoneTwilioMetrics, ...twilioDailyMetrics }
    const rawUnifiedMetrics = {
      queueSize: _.sumBy([phoneTcxMetrics, phoneTwilioMetrics], 'queueSize'),
      activeSize: _.sumBy([phoneTcxMetrics, phoneTwilioMetrics], 'activeSize'),
      ...unifiedPhoneDailyMetrics
    }

    const cards = [
      ...commonMetrics,
      {
        title: 'TMA',
        key: 'tma',
        formatFn: formatTimeData
      }
    ]

    return [
      getFormatMetricsFn(rawTcxMetrics, cards),
      getFormatMetricsFn(rawTwilioMetrics, cards),
      getFormatMetricsFn(rawUnifiedMetrics, cards)
    ]
  }

  getShiftsData = () => {
    const { getChannelShifts, getFormatShiftsFn, commonShifts } = this.props

    const [phoneShifts] = getChannelShifts(this.unifiedChannelName)
    const phoneCSI = this.getPhoneCSI(phoneShifts)

    const rawShiftsData = { ...phoneShifts, ...phoneCSI }

    const cards = [
      {
        title: 'Disponível 3cx',
        key: 'loggedIn3cx',
        className: 'green'
      },
      {
        title: 'Disponível Twilio',
        key: 'loggedInTwilio',
        className: 'green'
      },
      ...commonShifts,
      {
        title: 'Extras',
        key: 'extra'
      },
      {
        title: 'Faltantes',
        key: 'missing'
      }
    ]

    return getFormatShiftsFn(rawShiftsData, cards)
  }

  getPhoneCSI = ({ scheduled_members: scheduledMembers }) => {
    const { getTwilioMetricsByChannel, workforceMembers } = this.props

    const [availableTwilio, unavailableMembers] = getTwilioMetricsByChannel(this.unifiedChannelName)
    const availableTcx = this.getTcxLoggedInEmailList()

    const availableMembers = [...availableTwilio, ...availableTcx]
    const loggedIn3cx = availableTcx.length
    const loggedInTwilio = availableTwilio.length

    let extra = 0
    let missing = 0

    if (
      isObjectWithProps(scheduledMembers) &&
      isObjectWithProps(workforceMembers) &&
      isFilledArray(availableMembers)
    ) {
      const listOfScheduledEmails = []

      for (const member_id in scheduledMembers) {
        const memberInfo = workforceMembers['by_id'][member_id]

        if (isObjectWithProps(memberInfo)) {
          listOfScheduledEmails.push(memberInfo.email)
        }
      }

      extra = _.uniq(_.difference(availableMembers, listOfScheduledEmails)).length
      missing = _.uniq(_.difference(listOfScheduledEmails, availableMembers)).length
    }

    return { availableMembers, unavailableMembers, loggedIn3cx, loggedInTwilio, extra, missing }
  }

  getTcxLoggedInEmailList = () => {
    const { loggedInExtensions, workforceMembers } = this.props

    const availableMembers = []

    if (isObjectWithProps(loggedInExtensions) && isObjectWithProps(workforceMembers)) {
      const tcxEntries = Object.keys(loggedInExtensions['at_view'])

      for (const extension of tcxEntries) {
        const member = workforceMembers['by_extension'][extension]

        if (isObjectWithProps(member)) {
          availableMembers.push(member.email)
        }
      }
    }

    return _.uniq(availableMembers) || []
  }

  render = () => {
    const { mode } = this.props
    const { tcxMetrics, twilioMetrics, unifiedMetrics, shiftsData, showUnifiedMetrics } = this.state

    const text = showUnifiedMetrics ? 'Telefone' : '3cx e Twilio'

    return (
      <WallboardCard
        title={text}
        key={text}
        width={100}
        mode={mode}
        unifiedCard
        channel={this.channelId}
        switchChecked={showUnifiedMetrics}
        switchHandle={() => this.setState({ showUnifiedMetrics: !showUnifiedMetrics })}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '100%' }}>
            {showUnifiedMetrics ? (
              <WallboardCardData
                data={unifiedMetrics}
                channel={this.channelId}
                withNoTable
                unifiedCard
              />
            ) : (
              <>
                <WallboardCardData
                  data={tcxMetrics}
                  channel={this.channelId}
                  smallText
                  withNoTable
                />
                <WallboardCardData
                  data={twilioMetrics}
                  channel={this.channelId}
                  smallText
                  withNoTable
                />
              </>
            )}
          </div>
          <div style={{ width: '25%' }}>
            <WallboardCardData tableData={shiftsData} channel={this.channelId} withNoCards />
          </div>
        </div>
      </WallboardCard>
    )
  }
}

export default PhoneSummary
