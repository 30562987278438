import React from 'react'

import style from './style.module.css'

import { capitalizeStr } from '../../core/helpers/GenericHelper'

const TwilioAgentsTable = ({ data, sortBy }) => {
  const createBallColor = status => {
    const map = {
      Disponível: style.backgroundGreen,
      Pausado: style.backgroundOrange,
      Indisponível: style.backgroundRed,
      Ausente: style.backgroundYellow
    }

    const colorClass = status in map ? map[status] : style.backgroundGray

    return <span title={status} className={`${style.ball} ${colorClass}`} />
  }

  return (
    <div className={style.wallboardTableContainer}>
      <table className={style.wallboardTableHeader} cellSpacing="10px" cellPadding="10px">
        <thead>
          <tr>
            <th className={style.wallboardTableSortButton} onClick={() => sortBy('name')}>
              Nome
            </th>

            <th className={style.wallboardTableSortButton} onClick={() => sortBy('assigned_tasks')}>
              AS
            </th>
            <th className={style.wallboardTableNumberColumn}>CP</th>
            <th className={style.wallboardTableNumberColumn}>TA</th>
            <th className={style.wallboardTableSortButton} onClick={() => sortBy('channel')}>
              Canal
            </th>
            <th className={style.wallboardTableNumberColumn}>TMI V2</th>
            <th className={style.wallboardTableNumberColumn}>TMA</th>

            <th className={style.wallboardTableNumberColumn}>NS</th>
            <th className={style.wallboardTableNumberColumn}>NSI V2</th>

            <th className={style.wallboardTableSortButton} onClick={() => sortBy('squadName')}>
              Mesa
            </th>
            <th className={style.wallboardTableNumberColumn}>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((agent, index) => (
            <tr className={style.wallboardTableRow} key={index}>
              <td>{String(agent.name)}</td>
              <td className={style.wallboardTableNumberColumn}>
                {agent.assigned_tasks ? agent.assigned_tasks : '-'}
              </td>
              <td className={style.wallboardTableNumberColumn}>
                {agent.capacity ? agent.capacity : '-'}
              </td>
              <td className={style.wallboardTableNumberColumn}>{capitalizeStr(agent.team)}</td>
              <td className={style.wallboardTableNumberColumn}>
                {agent.channel ? agent.channel : '-'}
              </td>
              <td className={style.wallboardTableNumberColumn}>{agent.total_tmi}</td>
              <td className={style.wallboardTableNumberColumn}>{agent.total_tma}</td>
              <td className={style.wallboardTableNumberColumn}>{agent.serviceLevel}</td>
              <td className={style.wallboardTableNumberColumn}>{agent.nsi}</td>
              <td className={style.wallboardTableNumberColumn}>
                {agent.squadName ? agent.squadName : '-'}
              </td>
              <td className={style.wallboardTableNumberColumn}>
                {agent.status ? createBallColor(agent.status) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TwilioAgentsTable
