import React from 'react'

import { isFilledArray } from '../../core/helpers/GenericHelper'

import style from './style.module.css'

const WallboardCSITable = ({ data, turnedAgents, missingAgents }) => {
  const wasBadgeNameFound = (badgeArray, char) =>
    badgeArray.find(({ badgeName }) => badgeName === char)

  const generateBadge = channels => {
    const badgeArr = []

    channels.forEach(channel => {
      if (channel === 'email' && !wasBadgeNameFound(badgeArr, 'M')) {
        badgeArr.push({
          badgeName: 'M',
          badgeClass: style['badgeEmail'],
          badgeTitle: 'Email'
        })
      } else if (channel === 'voice' && !wasBadgeNameFound(badgeArr, 'T')) {
        badgeArr.push({
          badgeName: 'T',
          badgeClass: style['badgeVoice'],
          badgeTitle: 'Telefone'
        })
      } else if (
        channel === 'whatsapp' &&
        !channels.includes('chat') &&
        !wasBadgeNameFound(badgeArr, 'W')
      ) {
        badgeArr.push({
          badgeName: 'W',
          badgeClass: style['badgeWhatsapp'],
          badgeTitle: 'Whatsapp'
        })
      } else if (
        channel === 'chat' &&
        !channels.includes('whatsapp') &&
        !wasBadgeNameFound(badgeArr, 'C')
      ) {
        badgeArr.push({
          badgeName: 'C',
          badgeClass: style['badgeChat'],
          badgeTitle: 'Chat'
        })
      } else if (
        (channel === 'escritos' || (channel === 'chat' && channels.includes('whatsapp'))) &&
        !wasBadgeNameFound(badgeArr, 'E')
      ) {
        badgeArr.push({
          badgeName: 'E',
          badgeClass: style['badgeEscritos'],
          badgeTitle: 'Escritos'
        })
      }
    })

    return badgeArr.map(({ badgeTitle, badgeClass, badgeName }) => (
      <span
        key={badgeTitle}
        title={badgeTitle}
        className={style.wallboardBadge + ` badge ${badgeClass}`}
      >
        {badgeName}
      </span>
    ))
  }

  return (
    <div className={style.wallboardTableContainer}>
      <table className={style.wallboardTableHeader}>
        <thead>
          <tr>
            <th title="NOME">Nome</th>

            <th title={turnedAgents ? 'TIME ATENDENDO' : 'MESA'}>{turnedAgents ? 'TA' : 'Mesa'}</th>

            {!missingAgents ? <th title="ATENDIMENTOS SIMULTÂNEOS">AS</th> : null}

            {!missingAgents ? (
              <th key="capacity" title="CAPACIDADE">
                CP
              </th>
            ) : null}

            <th title="RECEBIDAS">R</th>

            {turnedAgents ? <th title="CANAL ESPERADO">CE</th> : null}

            {turnedAgents ? <th>&nbsp;</th> : null}

            <th title="CANAL ATUAL">CANAL</th>
          </tr>
        </thead>
        <tbody>
          {data.map((agent, index) => {
            const {
              memberName,
              squadName,
              currentTeam,
              assignedTasks,
              capacity,
              answeredInformation,
              shiftedChannels,
              currentChannels
            } = agent

            const hasChannels = isFilledArray(shiftedChannels)

            return (
              <tr className={style.wallboardTableRow} key={index}>
                {/* NOME */}
                <td className={style.wallboardTableLongColumn}>
                  {String(memberName).replace('| Stone', '')}
                </td>

                {/* MESA - TA */}
                <td className={style.wallboardTableLongColumn}>
                  {turnedAgents && currentTeam
                    ? currentTeam
                    : !turnedAgents && squadName
                    ? squadName
                    : '-'}
                </td>

                {/* AS */}
                {!missingAgents ? (
                  <td className={style.wallboardTableNumberColumn}>
                    {assignedTasks || assignedTasks === 0 ? assignedTasks : '-'}
                  </td>
                ) : null}

                {/* CP */}
                {!missingAgents ? (
                  <td className={style.wallboardTableNumberColumn}>{capacity ? capacity : '-'}</td>
                ) : null}

                {/* R */}
                <td className={style.wallboardTableNumberColumn}>{answeredInformation}</td>

                {/* CE */}
                {turnedAgents ? (
                  <td className={style.wallboardTableNumberColumn}>
                    {hasChannels ? generateBadge(shiftedChannels) : '-'}
                  </td>
                ) : null}

                {turnedAgents ? <td>→</td> : null}

                {/* CANAL */}
                <td className={style.wallboardTableNumberColumn}>
                  {missingAgents && hasChannels
                    ? generateBadge(shiftedChannels)
                    : isFilledArray(currentChannels)
                    ? generateBadge(currentChannels)
                    : '-'}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default WallboardCSITable
