import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'

import store from './redux/createStore'
import routes from './routes'

import './styles/index.module.css'

import amplitude from './core/services/amplitude'

amplitude.init()

const App = () => (
  <Provider store={store}>
    <Router>{routes}</Router>
  </Provider>
)

ReactDOM.render(<App />, document.getElementById('root'))
