import React from 'react'
import { bool, func } from 'prop-types'
import classnames from 'classnames'

import style from './style.module.css'
import darkStyle from './darkStyle.module.css'

function Switch({ disabled, onChange, checked, showText, darkTheme }) {
  const className = classnames(style.switch, {
    [style.checked]: checked,
    [style.disabled]: disabled
  })

  const classNameDark = classnames(darkStyle.switch, {
    [darkStyle.checked]: checked,
    [darkStyle.disabled]: disabled
  })

  return (
    <div className={darkTheme ? classNameDark : className}>
      <input
        className={style.input}
        checked={checked}
        type="checkbox"
        onChange={() => !disabled && onChange(!checked)}
      />
      {showText ? <span>{checked ? 'on' : 'off'}</span> : null}
    </div>
  )
}

Switch.propTypes = {
  disabled: bool,
  onChange: func.isRequired,
  checked: bool,
  showText: bool,
  darkTheme: bool
}

Switch.defaultProps = {
  disabled: false,
  checked: false,
  showText: bool,
  darkTheme: false
}

export default Switch
