import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'

import SideBar from '../../components/SideBar'
import WallboardCard from '../../components/WallboardCard'
import TwilioAgentsTable from '../../components/WallboardCard/TwilioAgentsTable'
import SquadFilters from '../../components/SquadFilters'

import * as socketService from '../../core/services/socketService'

import {
  filterBySquadNameOrLeaderFn,
  isFilledArray,
  isObjectWithProps
} from '../../core/helpers/GenericHelper'

import style from './style.module.css'

class TwilioAgents extends Component {
  state = {
    agentsData: undefined,
    filteredAgentsData: undefined,
    sortedKey: null,
    sortedPosition: 'asc',
    selectedInputValue: ''
  }

  socketRoom = 'twilioAgents'

  statusOrder = ['Disponível', 'Pausado', 'Ausente', 'Indisponível', 'Offline']
  agentsOrdenation = ['statusPosition', 'squadName', 'channel', 'name']

  componentDidMount() {
    const { selectedView } = this.props
    this._isMounted = true

    if (isObjectWithProps(selectedView)) {
      socketService.enterSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  componentDidUpdate(prevProps) {
    const { disconectSocketChannel, enterSocketChannel } = socketService
    const { socketInformationTrigger, selectedView } = this.props

    if (isObjectWithProps(selectedView)) {
      const { name: viewName } = selectedView
      const { selectedView: prevSelectedView } = prevProps

      if (!isObjectWithProps(prevSelectedView) || prevSelectedView.name !== viewName) {
        if (isObjectWithProps(prevSelectedView)) {
          disconectSocketChannel(this.socketRoom, [prevSelectedView.name])
        }

        enterSocketChannel(this.socketRoom, [viewName])
      }
    }

    if (prevProps.socketInformationTrigger !== socketInformationTrigger) {
      this.updateTwilioMembers()
    }
  }

  componentWillUnmount() {
    const { selectedView } = this.props
    this._isMounted = false

    if (isObjectWithProps(selectedView)) {
      socketService.disconectSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  updateTwilioMembers = () => {
    const { socketInformation } = socketService

    if (isObjectWithProps(socketInformation)) {
      const { agentDailyMetrics, twilioWorkers, workforceMembers } = socketInformation

      if (
        isFilledArray(agentDailyMetrics) &&
        isObjectWithProps(twilioWorkers) &&
        isObjectWithProps(workforceMembers)
      ) {
        this.defineTwilioAgents(
          agentDailyMetrics,
          twilioWorkers['from_view'],
          workforceMembers['by_email']
        )
      }
    }
  }

  getAgentTMI = agentTasks => {
    const interactionTime = _.sumBy(agentTasks, 'total_interaction_time')
    const interactionBlocks = _.sumBy(agentTasks, 'total_interaction_blocks')

    if (interactionBlocks) {
      return Number(interactionTime / interactionBlocks).toFixed(1) + 's'
    }

    return '-'
  }

  getAgentTMA = agentTasks => {
    const totalDuration = _.sumBy(agentTasks, 'total_duration')
    const totalReceived = _.sumBy(agentTasks, 'total_received')

    if (totalReceived !== 0) {
      return Number(totalDuration / (totalReceived * 60)).toFixed(1) + 'm'
    }

    return '-'
  }

  getAgentServiceLevel = agentTasks => {
    const answeredInTime = _.sumBy(agentTasks, 'answered_in_time')
    const totalReceived = _.sumBy(agentTasks, 'total_received')

    if (totalReceived !== 0) {
      return Number((answeredInTime * 100) / totalReceived).toFixed(2) + '%'
    }

    return '-'
  }

  getAgentNSI = agentTasks => {
    const interactionBlocks = _.sumBy(agentTasks, 'total_interaction_blocks')
    const interactedInTime = _.sumBy(agentTasks, 'interacted_in_time') || 0

    if (interactionBlocks) {
      return Number((interactedInTime * 100) / interactionBlocks).toFixed(2) + '%'
    }

    return '-'
  }

  getStatusWeightPosition = status => {
    const { statusOrder } = this
    const orderWeight = statusOrder.indexOf(status) + 1

    return orderWeight > 0 ? orderWeight : statusOrder.length
  }

  getChannelCaption = channels => {
    if (channels.includes('chat') && !channels.includes('whatsapp')) {
      return 'C'
    }

    if (channels.includes('whatsapp') && !channels.includes('chat')) {
      return 'W'
    }

    if (channels.includes('whatsapp') && channels.includes('chat')) {
      return 'E'
    }

    if (channels.includes('voice')) {
      return 'T'
    }

    return '-'
  }

  defineTwilioAgents = (agentDailyMetrics, twilioWorkers, workforceMembers) => {
    const agentsCollection = _.flatten(Object.values(twilioWorkers).map(v => Object.entries(v)))
    const formattedAgents = []

    for (const [agentEmail, [agentInfo]] of agentsCollection) {
      const agentMember = workforceMembers[agentEmail]

      if (!isObjectWithProps(agentMember)) {
        continue
      }

      const { name, assigned_tasks, capacity, channels, status, teams } = agentInfo
      const { nome_mesa__c: squadName, nome_do_lider_de_operacao__c, ramal_3cx__c } = agentMember

      const agentTasks = agentDailyMetrics.filter(({ agent }) =>
        [agentEmail, ramal_3cx__c].includes(agent)
      )

      formattedAgents.push({
        name,
        status,
        squadName: squadName || '(?)',
        nome_do_lider_de_operacao__c,
        capacity,
        assigned_tasks,
        total_tmi: this.getAgentTMI(agentTasks),
        total_tma: this.getAgentTMA(agentTasks),
        serviceLevel: this.getAgentServiceLevel(agentTasks),
        nsi: this.getAgentNSI(agentTasks),
        statusPosition: this.getStatusWeightPosition(status),
        channel: this.getChannelCaption(channels),
        team: isFilledArray(teams) ? teams[0] : '-'
      })
    }

    const sortedAgents = this.getSortedAgents(formattedAgents)

    this._isMounted &&
      this.setState({ agentsData: sortedAgents }, () => this.defineFilteredMembers())
  }

  defineFilteredMembers = () => {
    const { agentsData, selectedInputValue } = this.state

    const filteredAgentsData = agentsData.filter(filterBySquadNameOrLeaderFn(selectedInputValue))

    const sortedAgentsData = this.getSortedAgents(filteredAgentsData)

    this._isMounted && this.setState({ filteredAgentsData: sortedAgentsData })
  }

  getSortedAgents = agentsData => {
    const { sortedKey, sortedPosition } = this.state

    const sortFilters = sortedKey ? [sortedKey, 'statusPosition'] : this.agentsOrdenation
    const sortPositions = sortedKey ? [sortedPosition, 'asc'] : _.times(4, () => 'asc')

    return _.orderBy(agentsData, sortFilters, sortPositions)
  }

  sortBy = sortedKey => {
    const { sortedPosition: lastSortedPosition, sortedKey: lastSortedKey } = this.state

    const sortedPosition =
      sortedKey !== lastSortedKey ? 'asc' : lastSortedPosition === 'asc' ? 'desc' : 'asc'

    this._isMounted &&
      this.setState({ sortedKey, sortedPosition }, () => this.defineFilteredMembers())
  }

  handleSearch = event =>
    this._isMounted &&
    this.setState({ selectedInputValue: event.target.value.toLowerCase() }, () =>
      this.defineFilteredMembers()
    )

  render = () => {
    const { filteredAgentsData, agentsData } = this.state

    const displayedData = filteredAgentsData || agentsData || []

    return (
      <>
        <SideBar />

        <div id="general-wallboard">
          <div className={style.generalWallboardCSI}>
            <div className={style.captionWrapper}>
              <div>
                <span>AS = ATENDIMENTOS SIMULTÂNEOS </span>
                <span>CP = CAPACIDADE </span>
                <span>TA = TIME ATENDENDO </span>
              </div>

              <div>
                <span>CANAL: Telefone (T) - Escritos (E) - Chat (C) - WhatsApp (W)</span>
              </div>

              <div>
                <span>TMI V2 = TEMPO MÉDIO ENTRE INTERAÇÕES</span>
                <span>TMA = TEMPO MÉDIO DE ATENDIMENTO </span>
                <span>NS = NÍVEL DE SERVIÇO </span>
                <span>NSI V2 = NÍVEL DE SERVIÇO ENTRE INTERAÇÕES </span>
              </div>
            </div>

            <SquadFilters handleSearch={this.handleSearch} />

            <div>
              <WallboardCard
                width={100}
                title={`Agentes Canais Escritos - ${displayedData.length}`}
                color="green"
              >
                <TwilioAgentsTable data={displayedData} sortBy={this.sortBy} />
              </WallboardCard>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ ...state.sideBarReducer, ...state.globalReducer })

export default connect(mapStateToProps)(withRouter(TwilioAgents))
