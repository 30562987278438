import React from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import style from './style.module.css'

const WallboardCardDataTable = props => {
  const renderTableData = () =>
    props.tableData.map(data => (
      <div
        className={style.cardContentItemMini}
        style={{
          width: props.writtenTable
            ? `${(100 / Math.ceil(props.tableData.length / 4)).toFixed(2)}%`
            : `${(100 / Math.ceil(props.tableData.length / 3)).toFixed(2)}%`
        }}
        key={shortid.generate()}
      >
        <div className={style.cardContentTitle}>{data.title}</div>
        <div className={props.writtenTable ? style.cardContentData : style.phoneCardContentData}>
          <div className={style[`${data.className}`]}>{data.info}</div>
        </div>
      </div>
    ))

  return (
    <div
      className={
        props.channel === 1
          ? style.smallCardContentItemTableUnified
          : style.cardContentItemTableUnified
      }
    >
      <div className={style.tableRowUnified}>{renderTableData()}</div>
    </div>
  )
}

WallboardCardDataTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      info: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  writtenTable: PropTypes.bool
}

WallboardCardDataTable.defaultProps = {
  tableData: [],
  writtenTable: false
}

export default WallboardCardDataTable
