import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'lodash'

import HalfHourlyChart from '../../components/HalfHourlyChart'
import SideBar from '../../components/SideBar'
import ChartDataset from '../../core/models/ChartDataset'

import {
  defineYTicks,
  formatServiceLevelValue,
  getEmptyChartData,
  getShouldUpdateStr
} from '../../core/helpers/ChartHelpers'
import { isFilledArray, isObjectWithProps } from '../../core/helpers/GenericHelper'

import * as socketService from '../../core/services/socketService'

class TMA extends Component {
  state = {
    phoneChartData: {},
    chatChartData: {},
    whatsAppChartData: {},
    unifiedChannelsChartData: {}
  }

  socketRoom = 'tma'

  channelMapping = {
    Telefone: { channelFilter: [1], hasNSI: false, stateKey: 'phoneChartData' },
    Escritos: { channelFilter: [2, 4], hasNSI: true, stateKey: 'unifiedChannelsChartData' },
    Chat: { channelFilter: [2], hasNSI: true, stateKey: 'chatChartData' },
    WhatsApp: { channelFilter: [4], hasNSI: true, stateKey: 'whatsAppChartData' }
  }

  componentDidMount() {
    const { selectedView } = this.props
    this._isMounted = true

    if (isObjectWithProps(selectedView)) {
      socketService.enterSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedView, socketInformationTrigger } = this.props
    const { enterSocketChannel, disconectSocketChannel } = socketService

    if (isObjectWithProps(selectedView)) {
      const { name: viewName } = selectedView
      const { selectedView: prevSelectedView } = prevProps

      if (!isObjectWithProps(prevSelectedView) || prevSelectedView.name !== viewName) {
        if (isObjectWithProps(prevSelectedView)) {
          disconectSocketChannel(this.socketRoom, [prevSelectedView.name])
        }

        enterSocketChannel(this.socketRoom, [viewName])
      }
    }

    if (prevProps.socketInformationTrigger !== socketInformationTrigger) {
      this.updateTMAChart()
    }
  }

  componentWillUnmount() {
    const { selectedView } = this.props
    this._isMounted = false

    if (isObjectWithProps(selectedView)) {
      socketService.disconectSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  updateTMAChart = () => {
    const { socketInformation } = socketService

    if (isObjectWithProps(socketInformation)) {
      const { halfHourlyMetrics: data } = socketInformation

      const { Chat, WhatsApp, Escritos, Telefone } = this.channelMapping

      if (data) {
        this.updateChart(data, Chat)
        this.updateChart(data, WhatsApp)
        this.updateChart(data, Escritos)
        this.updateChart(data, Telefone)
      }
    }
  }

  updateChart = (data, { stateKey, channelFilter, hasNSI }) => {
    const chartData = getEmptyChartData().map(item => {
      const hour = item.hour
      const minute = item.minute

      const halfHourlyMetrics =
        hour in data && isFilledArray(data[hour][minute])
          ? data[hour][minute].filter(h => channelFilter.includes(h.channel_id))
          : []

      if (isFilledArray(halfHourlyMetrics)) {
        if (halfHourlyMetrics.find(hm => hm.total_received > 0)) {
          const totalDuration = _.sumBy(halfHourlyMetrics, 'total_duration')
          const answeredInTime = _.sumBy(halfHourlyMetrics, 'answered_in_time')
          const totalReceived = _.sumBy(halfHourlyMetrics, 'total_received')

          item.realized = Number(totalDuration / totalReceived)
          item.serviceLevel = formatServiceLevelValue(answeredInTime / totalReceived)
        }

        if (halfHourlyMetrics.find(hm => hm.total_interaction_blocks > 0)) {
          const interactionBlocks = _.sumBy(halfHourlyMetrics, 'total_interaction_blocks')
          const interactedInTime = _.sumBy(halfHourlyMetrics, 'interacted_in_time') || 0

          item.nsi = formatServiceLevelValue(interactedInTime / interactionBlocks)
        }
      }

      return item
    })

    this._isMounted &&
      this.setState({
        [stateKey]: new ChartDataset(chartData, 'tma-with-service-level', hasNSI),
        [getShouldUpdateStr(stateKey)]: true
      })
  }

  render = () => {
    const { state, channelMapping } = this

    return (
      <>
        <SideBar />

        <div id="noc-monitoring">
          {Object.entries(channelMapping).map(([channelName, { stateKey }], index) => {
            const channelState = state[stateKey]

            return (
              <HalfHourlyChart
                key={index}
                title={channelName}
                data={channelState}
                ticks={defineYTicks(channelState, 1)}
                dataStateName={stateKey}
                component={this}
              />
            )
          })}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ ...state.sideBarReducer, ...state.globalReducer })

export default connect(mapStateToProps)(withRouter(TMA))
