import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import moment from 'moment'

import Table from '../../components/Table'
import SideBar from '../../components/SideBar'
import Spin from '../../components/Spin'

import WallboardServices from '../../core/services/WallboardServices'
import * as socketService from '../../core/services/socketService'

import { isObjectWithProps } from '../../core/helpers/GenericHelper'

import style from './style.module.css'

class Shifts extends Component {
  state = {
    shifts: [],
    formattedShifts: [],
    workforceMembers: {},
    department: '0',
    channel: '0',
    date: moment().format('YYYY-MM-DD'),
    lastQueriedDate: undefined,
    loading: false,
    clickedOnGetBtn: false
  }

  socketRoom = 'shifts'

  componentDidMount() {
    const { selectedView } = this.props
    this._isMounted = true

    if (isObjectWithProps(selectedView)) {
      socketService.enterSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { disconectSocketChannel, enterSocketChannel } = socketService
    const { socketInformationTrigger, selectedView } = this.props
    const { channel, department, date, clickedOnGetBtn } = this.state

    if (isObjectWithProps(selectedView)) {
      const { name: viewName } = selectedView
      const { selectedView: prevSelectedView } = prevProps

      if (!isObjectWithProps(prevSelectedView) || prevSelectedView.name !== viewName) {
        if (isObjectWithProps(prevSelectedView)) {
          disconectSocketChannel(this.socketRoom, [prevSelectedView.name])
        }

        enterSocketChannel(this.socketRoom, [viewName])
      }
    }

    if (prevProps.socketInformationTrigger !== socketInformationTrigger) {
      this.updateMembersInformation()
    }

    if (
      clickedOnGetBtn &&
      (prevState.channel !== channel ||
        prevState.department !== department ||
        prevState.date !== date)
    ) {
      this._isMounted && this.setState({ clickedOnGetBtn: false })
    }
  }

  componentWillUnmount() {
    const { selectedView } = this.props
    this._isMounted = false

    if (isObjectWithProps(selectedView)) {
      socketService.disconectSocketChannel(this.socketRoom, [selectedView.name])
    }
  }

  updateMembersInformation = () => {
    const { socketInformation } = socketService

    if (socketInformation && socketInformation.workforceMembers) {
      const workforceMembers = socketInformation.workforceMembers['by_id']

      this._isMounted && this.setState({ workforceMembers }, () => this.defineFormattedShifts())
    }
  }

  getShifts = () => {
    const { date, lastQueriedDate } = this.state

    this._isMounted && this.setState({ loading: true, clickedOnGetBtn: true })

    if (this.areFiltersSelected()) {
      if (date === lastQueriedDate) {
        this.defineFormattedShifts()
      } else {
        this._isMounted && this.setState({ lastQueriedDate: date })

        const desiredViews = Object.keys(this.props.views).join(',')

        WallboardServices.getShiftsForDate(date, desiredViews).then(({ data }) => {
          this.defineFormattedShifts(data.data)
        })
      }
    }
  }

  defineFormattedShifts = (customShifts = undefined) => {
    const { channel, department, workforceMembers, shifts } = this.state

    const desiredShifts = customShifts || shifts

    const filteredShifts = desiredShifts.filter(({ memberId, channelId }) => {
      const currentMember = workforceMembers[memberId]

      return (
        currentMember && currentMember.perfil__c === department && channelId === parseInt(channel)
      )
    })

    const formattedShifts = filteredShifts.map(shift => {
      const formattedShift = {}

      Object.keys(shift).forEach(key => {
        const currentMember = workforceMembers[shift[key]]

        if (key === 'memberId' && currentMember) {
          formattedShift.email = currentMember.email
          formattedShift.name = currentMember.outlook_name
        } else if (typeof shift[key] === 'string') {
          formattedShift[key] = shift[key]
        } else {
          formattedShift[key] = ' '
        }
      })

      return formattedShift
    })

    this._isMounted && this.setState({ shifts: desiredShifts, formattedShifts, loading: false })
  }

  downloadShiftsCsv = () => {
    const { formattedShifts: currentShiftsArray } = this.state

    const fieldOrder = ['name', 'date', 'shiftId']
    const date = currentShiftsArray[0].date

    for (let i = 0; i < 24; i += 1) {
      for (const minute of ['00', '30']) {
        fieldOrder.push(`h_${i}_${minute}`)
      }
    }

    const csvContent = ['data:text/csv;charset=utf-8,', fieldOrder.join(',')]

    currentShiftsArray.forEach(row => {
      const lineArray = []
      fieldOrder.forEach(campo => {
        lineArray.push(row[campo])
      })
      const line = lineArray.join(',')
      csvContent.push(line)
    })

    const encodedUri = encodeURI(csvContent.join('\n'))
    const link = document.createElement('a')

    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `escala_liquida_${date}.csv`)

    document.body.appendChild(link)
    link.click()
  }

  generateTableHeaders = () => {
    const tableHeaders = [
      {
        headerText: 'Nome',
        accessor: 'name',
        width: '200'
      },
      {
        headerText: 'Email',
        accessor: 'email',
        width: '220'
      }
    ]

    for (let i = 0; i < 24; i += 1) {
      for (const minute of ['00', '30']) {
        const columnShape = {
          headerText: `${i}:${minute}`,
          accessor: `h_${i}_${minute}`,
          width: '50'
        }
        tableHeaders.push(columnShape)
      }
    }
    return tableHeaders
  }

  setFilter = (event, stateName) =>
    this._isMounted && this.setState({ [stateName]: event.target.value })

  areFiltersSelected = () => {
    const { department, channel, date } = this.state

    return department !== '0' && channel !== '0' && date
  }

  render = () => {
    const { formattedShifts, loading, channel, department, date, clickedOnGetBtn } = this.state

    return (
      <>
        <SideBar />

        <div style={{ textAlign: 'center' }}>
          <div>
            <div style={{ width: '10%', display: 'inline-block' }}>
              <h3>Departamento</h3>
              <select onChange={e => this.setFilter(e, 'department')} value={department}>
                <option value="0">-</option>
                <option value="Front Stone">Front Stone</option>
                <option value="RAV">RAV</option>
                <option value="RT">GPS</option>
                <option value="Ilha VIP">Safety</option>
                <option value="Retencao">Retenção</option>
                <option value="Dexter">Dexter</option>
                <option value="Bolt">Bolt</option>
                <option value="Antecipação">Antecipação</option>
                <option value="Help TI">Help TI</option>
                <option value="Reconquista">Reconquista</option>
                <option value="Cyber">Cyber</option>
              </select>
            </div>
            <div style={{ width: '10%', display: 'inline-block' }}>
              <h3>Canal</h3>
              <select onChange={e => this.setFilter(e, 'channel')} value={channel}>
                <option value="0">-</option>
                <option value="1">Telefone</option>
                <option value="2">Chat</option>
                <option value="3">Email</option>
                <option value="4">WhatsApp</option>
              </select>
            </div>
            <div style={{ width: '10%', display: 'inline-block' }}>
              <h3>Dia</h3>
              <input type="date" value={date} onChange={e => this.setFilter(e, 'date')} />
            </div>

            <br />

            <button className={style.dowloadButton} onClick={this.getShifts} disabled={loading}>
              <Spin spinning={loading}>Carregar Escalas</Spin>
            </button>
          </div>

          {formattedShifts.length > 0 ? (
            <>
              <div style={{ width: '100%', margin: 'auto', overflowX: 'scroll' }}>
                <Table
                  data={formattedShifts}
                  pageSize={25}
                  tableTitle="Escalas"
                  tableClassName="new-table-half"
                  headers={this.generateTableHeaders()}
                />
              </div>

              <div>
                <button className={style.dowloadButton} onClick={this.downloadShiftsCsv}>
                  Baixar escala como .csv
                </button>
              </div>
            </>
          ) : (
            <div>
              {!this.areFiltersSelected()
                ? 'Utilize os filtros para começarmos! 😆'
                : !loading && clickedOnGetBtn
                ? 'Nenhuma escala foi obtida utilizando os filtros selecionados! 😓'
                : ''}
            </div>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({ ...state.sideBarReducer, ...state.globalReducer })

export default connect(mapStateToProps)(withRouter(Shifts))
