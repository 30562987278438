import axios from 'axios'

import appConfig from './appConfig'

export default class AnalyticsServices {
  static getOauthSalesforceConfigurations() {
    const url = `${appConfig.analyticsApiUrl}/oauth/salesforce/configurations`

    return axios.get(url)
  }

  static getOauthSalesforceToken(code) {
    return new Promise(resolve => {
      const url = `${appConfig.analyticsApiUrl}/oauth/salesforce/token`

      axios
        .post(url, { code, redirect_uri: `${window.location.origin}/` })
        .then(res => resolve({ accessToken: res.data.access_token }))
        .catch(err => resolve({ err }, window.location.reload()))
    })
  }

  static validateSalesforceSId(sessionId) {
    return new Promise(resolve => {
      const url = `${appConfig.analyticsApiUrl}/oauth/salesforce/validate-sid`

      axios
        .post(url, { session_id: sessionId })
        .then(res => resolve(res.data))
        .catch(err => resolve({ valid: false }, window.location.reload()))
    })
  }
}
