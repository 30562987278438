import React from 'react'

function HealthCheck() {
  return (
    <div>
      <h1>Host: {window.location.host}</h1>
      <h1>Web App is running!</h1>
      <h1>Timestamp: {Date.now()}</h1>
    </div>
  )
}

export default HealthCheck
