import React, { Component } from 'react'
import _ from 'lodash'

import WallboardCardData from '../../components/WallboardCard/WallboardCardData'
import WallboardCard from '../../components/WallboardCard'

import { formatTimeData, formatPercentData } from '../../core/helpers/ChannelSummaryHelper'

class WrittenSummary extends Component {
  state = {
    showUnifiedChannels: true,
    chatMetrics: [],
    whatsAppMetrics: [],
    unifiedMetrics: [],
    unifiedShifts: []
  }

  channelName = 'Escritos'
  writtenChannels = {
    Chat: 2,
    WhatsApp: 4
  }

  componentDidMount() {
    this._isMounted = true

    this.refreshData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.twilioIncomingActive !== this.props.twilioIncomingActive) {
      this.refreshData()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  refreshData = () => {
    this._isMounted && this.setState({ ...this.getMetricsData(), ...this.getShiftsData() })
  }

  getMetricsData = () => {
    const { props, writtenChannels, zipChannelMetrics } = this
    const { getTwilioMetricsForViewByChannel, getDailyMetricsByChannel, getFormatMetricsFn } = props

    const channelIds = Object.values(writtenChannels)
    const channelNames = Object.keys(writtenChannels)

    const [chatTwilioMetrics, whatsAppTwilioMetrics] = channelIds.map(id =>
      getTwilioMetricsForViewByChannel(id)
    )
    const unifiedTwilioMetrics = zipChannelMetrics(chatTwilioMetrics, whatsAppTwilioMetrics)

    const [chatDailyMetrics, whatsAppDailyMetrics, unifiedDailyMetrics] = getDailyMetricsByChannel([
      ...channelNames,
      this.channelName
    ])

    const cards = [
      ...this.props.commonMetrics,
      {
        title: 'NSI V2',
        key: 'nsi',
        formatFn: formatPercentData
      },
      {
        title: 'TMA',
        key: 'tma',
        formatFn: formatTimeData
      },
      {
        title: 'TMI V2',
        key: 'tmi',
        formatFn: formatTimeData
      }
    ]

    const chatData = { ...chatDailyMetrics, ...chatTwilioMetrics }
    const chatMetrics = getFormatMetricsFn(chatData, cards)

    const whatsAppData = { ...whatsAppDailyMetrics, ...whatsAppTwilioMetrics }
    const whatsAppMetrics = getFormatMetricsFn(whatsAppData, cards)

    const unifiedData = { ...unifiedDailyMetrics, ...unifiedTwilioMetrics }
    const unifiedMetrics = getFormatMetricsFn(unifiedData, cards)

    return { chatMetrics, whatsAppMetrics, unifiedMetrics }
  }

  zipChannelMetrics = (chatMetrics, whatsAppMetrics) => {
    const writtenMetricsKeys = Object.keys(chatMetrics)
    const writenMetricsValues = writtenMetricsKeys.map(key =>
      _.sumBy([chatMetrics, whatsAppMetrics], key)
    )

    const zipped = _.zip(writtenMetricsKeys, writenMetricsValues)

    return _.fromPairs(zipped)
  }

  getShiftsData = () => {
    const { getChannelShifts, getFormatShiftsFn, commonShifts } = this.props

    const [unifiedShifts] = getChannelShifts(this.channelName)
    const [chatCSI, whatsAppCSI] = this.getWrittenCSI()

    const cards = [
      {
        title: 'Disponível Escritos',
        key: 'loggedIn',
        className: 'green'
      },
      {
        title: 'Só Chat',
        key: 'chatLoggedIn',
        className: 'green'
      },
      {
        title: 'Só Wpp',
        key: 'wppLoggedIn',
        className: 'green'
      },
      {
        title: 'Ausente Escritos',
        key: 'missing',
        className: 'orange'
      },
      {
        title: 'Só Chat',
        key: 'chatMissing',
        className: 'orange'
      },
      {
        title: 'Só Wpp',
        key: 'wppMissing',
        className: 'orange'
      },
      ...commonShifts
    ]

    const rawShiftsData = { ...unifiedShifts, ...this.formatUnifiedCSI(chatCSI, whatsAppCSI) }

    return { unifiedShifts: getFormatShiftsFn(rawShiftsData, cards) }
  }

  getWrittenCSI = () => {
    const { writtenChannels, props } = this

    return Object.keys(writtenChannels).map(channelName => {
      const [availableMembers, unavailableMembers] = props.getTwilioMetricsByChannel(channelName)

      return {
        availableMembers,
        unavailableMembers,
        loggedIn: availableMembers.length,
        extra: 0,
        missing: 0
      }
    })
  }

  formatUnifiedCSI = (chatCSI, whatsAppCSI) => {
    const available = 'availableMembers'
    const unavailable = 'unavailableMembers'

    const loggedIn = _.intersection(chatCSI[available], whatsAppCSI[available]).length
    const missing = _.intersection(chatCSI[unavailable], whatsAppCSI[unavailable]).length

    const chatLoggedIn = _.difference(chatCSI[available], whatsAppCSI[available]).length
    const chatMissing = _.difference(chatCSI[unavailable], whatsAppCSI[unavailable]).length

    const wppLoggedIn = _.difference(whatsAppCSI[available], chatCSI[available]).length
    const wppMissing = _.difference(whatsAppCSI[unavailable], chatCSI[unavailable]).length

    return { loggedIn, missing, chatLoggedIn, chatMissing, wppLoggedIn, wppMissing }
  }

  render = () => {
    const { mode } = this.props
    const {
      showUnifiedChannels,
      chatMetrics,
      whatsAppMetrics,
      unifiedMetrics,
      unifiedShifts
    } = this.state

    const text = showUnifiedChannels ? 'Escritos' : 'Chat e Whatsapp'

    return (
      <WallboardCard
        title={text}
        key={text}
        width={100}
        mode={mode}
        unifiedCard
        switchChecked={showUnifiedChannels}
        switchHandle={() => this.setState({ showUnifiedChannels: !showUnifiedChannels })}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ width: '100%' }}>
            {showUnifiedChannels ? (
              <WallboardCardData data={unifiedMetrics} withNoTable unifiedCard />
            ) : (
              <>
                <WallboardCardData data={chatMetrics} withNoTable />
                <WallboardCardData data={whatsAppMetrics} withNoTable />
              </>
            )}
          </div>
          <div style={{ width: '25%' }}>
            <WallboardCardData tableData={unifiedShifts} withNoCards writtenTable />
          </div>
        </div>
      </WallboardCard>
    )
  }
}

export default WrittenSummary
