import React, { Component } from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'

import LoginAzure from '../../components/LoginAzure'
import LoginSalesforce from '../../components/LoginSalesforce'

import amplitude from '../../core/services/amplitude'
import AnalyticsServices from '../../core/services/AnalyticsServices'
import WallboardServices from '../../core/services/WallboardServices'
import * as ViewHelper from '../../core/helpers/ViewHelper'
import { clearAllStorage } from '../../core/helpers/AuthHelpers'

import * as sideBarActions from '../../redux/actions/sideBarActions'
import * as globalActions from '../../redux/actions/globalActions'

import stoneLogo from '../../assets/images/stoneLogo.svg'

import style from './style.module.css'

class Login extends Component {
  state = {
    triggerSFLogin: false,
    triggerAzureLogin: false
  }

  componentDidMount() {
    this._isMounted = true

    this.setLoading(false)

    const { code, session_state } = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    })

    const token = localStorage.getItem('token')
    const refreshToken = localStorage.getItem('refreshToken')

    if (code || token) {
      session_state || refreshToken
        ? this.handleAzureLogin(code, token, refreshToken)
        : this.handleSalesforceLogin(code, token)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleAzureLogin = async (code, token, refreshToken) => {
    this.setLoading(true)

    let accessToken = token && token.split(';')[2]

    if (code) {
      ;({ accessToken, refreshToken } = await WallboardServices.getOauthAzureToken(code))
    }

    if (accessToken) {
      const {
        valid,
        validatedToken,
        ...identification
      } = await WallboardServices.getOauthAzureValidation(accessToken)

      if (valid) {
        this.getMemberInfo('azure', identification, validatedToken, refreshToken)
      } else {
        this.setState({ triggerAzureLogin: true })
      }
    }
    this.setLoading(false)
  }

  handleSalesforceLogin = async (code, token) => {
    this.setLoading(true)

    let accessToken = token && token.split(';')[2]

    if (code) {
      ;({ accessToken } = await AnalyticsServices.getOauthSalesforceToken(code))
    }

    if (accessToken) {
      const { valid, ...identification } = await AnalyticsServices.validateSalesforceSId(
        accessToken
      )

      if (valid) {
        this.getMemberInfo('salesforce', identification, accessToken)
      } else {
        this.setState({ triggerSFLogin: true })
      }
    }
    this.setLoading(false)
  }

  setLoading = loaderState => this.props.globalActions.toggleLoader(loaderState)

  setLocalStorageInformation = (userEmail = null, token = null, refreshToken = null) => {
    userEmail
      ? window.localStorage.setItem('userEmail', userEmail)
      : localStorage.removeItem('userEmail')
    token ? window.localStorage.setItem('token', token) : localStorage.removeItem('token')
    refreshToken
      ? window.localStorage.setItem('refreshToken', refreshToken)
      : localStorage.removeItem('refreshToken')
  }

  handleIsolatedView = ({ is_isolated }) =>
    this.props.sideBarActions.toggleIsolatedView(is_isolated)

  getUserViews = identifiers => {
    const { history, sideBarActions } = this.props

    WallboardServices.getWallboardViewsComplete(identifiers)
      .then(({ data }) => {
        const { data: userView } = data

        if (userView === 'blacklisted profile') {
          this.setLoading(false)
          history.push('/blacklisted-profiles')
        } else {
          const [viewName] = Object.keys(userView)

          amplitude.setSelectedView(viewName)
          amplitude.setProfile(identifiers.profile_name)

          const formattedView = {
            key: viewName,
            name: viewName,
            value: viewName,
            values: userView[viewName]
          }

          this.handleIsolatedView(userView[viewName])

          ViewHelper.storeUserView(formattedView, history.push, sideBarActions.updateSelectedView)
          this.setLoading(false)
        }
      })
      .catch(err => {
        this.setLoading(false)

        console.error('> Erro ao buscar pelas informações da view deste usuário!', err)

        clearAllStorage()
      })
  }

  getMemberInfo = (loginMode, identification, accessToken, refreshToken = null) => {
    this.setLocalStorageInformation(null, `${loginMode};;${accessToken}`, refreshToken)

    setTimeout(() => {
      this.setLoading(true)
      WallboardServices.getUserMember(identification, loginMode)
        .then(({ data }) => {
          const userInfo = data.data

          if (userInfo.length) {
            const [{ email, profile_name, planning_department }] = userInfo

            amplitude.setUserId(email)

            const token = `${loginMode};${email};${accessToken}`

            this.setLocalStorageInformation(email, token, refreshToken)

            this.getUserViews({ profile_name, planning_department, default_view: true })
          } else {
            this.setLoading(false)
            this.props.history.push('/blacklisted-profiles')
          }
        })
        .catch(err => {
          this.setLoading(false)

          console.error('> Erro ao buscar pelas informações do usuário!', err)

          clearAllStorage()
        })
    }, 500)
  }

  render = () => (
    <div id="login" className={style.login}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <div className={style.login__wrapper}>
          <p className={style.login__title}>WALLBOARD RC</p>

          <div style={{ margin: '2rem' }}>
            <LoginSalesforce
              isLoading={this.props.isLoading}
              setLoading={this.setLoading}
              triggerLogin={this.state.triggerSFLogin}
            />
            <LoginAzure
              isLoading={this.props.isLoading}
              setLoading={this.setLoading}
              triggerLogin={this.state.triggerAzureLogin}
            />
          </div>
        </div>
      </div>
      <img src={stoneLogo} alt="Logo" />
    </div>
  )
}

Login.propTypes = {
  sideBarActions: PropTypes.shape({
    updateSelectedView: PropTypes.func,
    toggleIsolatedView: PropTypes.func
  }).isRequired,
  globalActions: PropTypes.shape({
    toggleLoader: PropTypes.func
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool.isRequired
}

function mapStateToProps(state) {
  return { ...state.sideBarReducer, ...state.globalReducer }
}

function mapDispatchToProps(dispatch) {
  return {
    sideBarActions: bindActionCreators(sideBarActions, dispatch),
    globalActions: bindActionCreators(globalActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
