const {
  REACT_APP_ANALYTICS_API_URL,
  REACT_APP_WALLBOARD_API_URL,
  REACT_APP_WALLBOARD_SOCKET_URL,
  REACT_APP_AMPLITUDE_API_KEY
} = process.env

const config = {
  analyticsApiUrl: REACT_APP_ANALYTICS_API_URL,
  wallboardApiUrl: REACT_APP_WALLBOARD_API_URL,
  wallboardSocketURL: REACT_APP_WALLBOARD_SOCKET_URL,
  amplitudeApiKey: REACT_APP_AMPLITUDE_API_KEY
}

export default config
