export default class AllSubjectsTable {
  constructor(data) {
    this.bank = data.bank_name
    this.d21 = data.d_21
    this.d14 = data.d_14
    this.d7 = data.d_7
    this.d0 = data.d_0
    this.weeklyAverage = data.weekly_avg
    this.deviation = data.avg_adherence
    this.isWorrying = data.is_worrying
  }
}
