import React from 'react'

import style from './style.module.css'

const Spin = ({ spinning, children }) => (
  <div>
    <span className={!spinning ? style.invisible : ''}>
      <svg className={style.spinner} width="20px" height="20px" viewBox="0 0 66 66">
        <circle className={style.circle} fill="none" strokeLinecap="round" cx="33" cy="33" r="30" />
      </svg>
    </span>
    {children}
  </div>
)

export default Spin
