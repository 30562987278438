import React from 'react'

import Chart from '../Chart'

import { getShouldUpdateStr, handShakeFn } from '../../core/helpers/ChartHelpers'

const HalfHourlyChart = ({ title, data, ticks, dataStateName, component, chartHeight }) => {
  return (
    <Chart
      id={title}
      type="bar"
      data={data}
      title={title}
      labelFontSize={15}
      height={chartHeight || '47vh'}
      width="100%"
      extraYScales={
        ticks
          ? {
              ticks,
              spanGaps: false,
              gridLines: {
                display: false
              },
              id: 'percentage-y-axis',
              type: 'linear',
              position: 'right'
            }
          : undefined
      }
      shouldHavePlugin
      shouldUpdateInfo={component.state[getShouldUpdateStr(dataStateName)]}
      handShakeFn={() => handShakeFn(dataStateName, component)}
    />
  )
}

export default HalfHourlyChart
